import React from 'react';
import {
	Grid,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clx from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 56,
    	padding: theme.spacing(0, 2),
    },
    rootBorder: {
        borderBottom: '1px solid '+theme.palette.grey[300],
    },
    actions: {
        alignItems: 'center',
        display: 'flex',
        '& button, & a': {
            marginLeft: theme.spacing(1),
            padding: 6,

            '&:first-child': {
                marginLeft: 0
            }
        }
    },
    title: {
    	fontSize: '1rem',
        fontWeight: 500
    }
}));

function GuiPaperHeader(props) {
	const classes = useStyles();
    const { children, ...restProps } = props;

    const rootClassName = clx(
        classes.root,
        {[classes.rootBorder]: props.withBorder}
    )

    return (
        <Grid
        	className={rootClassName}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid
                item
            >
                { !!props.title && (
                    <Typography className={classes.title} variant="h6" component="h5">
                        {props.title}
                    </Typography>
                )}
                { !!props.actionsLeft && (
                    <div className={classes.actions}>
                        {props.actionsLeft}
                    </div>
                )}
            </Grid>
            <Grid
                className={classes.actions}
                item
            >
                {props.actions}
            </Grid>
        </Grid>
    );
}

GuiPaperHeader.propTypes = {
    withBorder: PropTypes.bool
};

GuiPaperHeader.defaultProps = {
    className: "",
    withBorder: true
};

export default GuiPaperHeader;