import React from 'react';

import clsx from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import { createMuiTheme, makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types'



const SideNav = (props) => {
    const theme = useTheme();

    const { drawerOpen, ...restProps } = props;

    const sideNavTheme = createMuiTheme({
        overrides: {
            MuiListItem: {
                root: {
                    "&$selected": {
                        backgroundColor: theme.sideNav.selectedBg,
                    },
                    "&$selected:hover": {
                        backgroundColor: theme.sideNav.selectedBg,
                    },
                    "&:hover": {
                        backgroundColor: theme.sideNav.selectedBg+"!important",
                    }
                },
            },
            MuiListItemIcon: {
                root: {
                    color: theme.sideNav.color,
                },
            },
            MuiListItemText: {
                root: {
                    whiteSpace: 'nowrap'
                }
            }
        },
    });

    const useStyles = makeStyles((theme) => ({
        drawer: {
            width: theme.sideNav.width,
            flexShrink: 0,
        },
        drawerClose: {
            width: 60
        },
        drawerPaper: {
            background: theme.sideNav.background,
            color: theme.sideNav.color,
            width: drawerOpen ? theme.sideNav.width : 60,

            '&:hover': {
                width: theme.sideNav.width
            }
        },
        drawerContainer: {
            overflow: 'auto',
        },
    }));
    const classes = useStyles();

    return (
        <Drawer
            {...restProps}
            className={clsx(
                classes.drawer,
                {[classes.drawerClose]: !props.drawerOpen}
            )}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerContainer}>
                <ThemeProvider theme={sideNavTheme}> 
                    { props.children }
                </ThemeProvider>
            </div>
        </Drawer>
    );
}

SideNav.propTypes = {
    // id: PropTypes.string.isRequired
};

SideNav.defaultProps = {
    className: [],
    color: "primary",
    disabled: false,
    drawerOpen: true,
    size: "medium",
    variant: "permanent"
};

export default SideNav;
