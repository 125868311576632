import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    root: {
	    width: 100,
	    height: 100,
        // transform: 'scale(2)',
	    margin: '0 auto'
	},
    checkIcon: {
        width: 80,
        height: 80,
        position: 'relative',
        borderRadius: '50%',
        boxSizing: 'content-box',
        border: '4px solid '+theme.palette.green.main,
        
        '&::before': {
            top: 3,
            left: -2,
            width: 30,
            transformOrigin: '100% 50%',
            borderRadius: '100px 0 0 100',
        },
        '&::after': {
            top: 0,
            left: 30,
            width: 60,
            transformOrigin: '0 50%',
            borderRadius: '0 100px 100px 0',
            animation: 'rotate-circle 4.25s ease-in',
        },
        '&::before, &::after': {
            content: '',
            height: 100,
            position: 'absolute',
            background: 'transparent',
            transform: 'rotate(-45deg)',
        }
    },

    lineTip: {
        animation: 'icon-line-tip 0.75s',
        height: 5,
        backgroundColor: theme.palette.green.main,
        display: 'block',
        borderRadius: 2,
        left: 14,
        position: 'absolute',
        top: 46,
        transform: 'rotate(45deg)',
        width: 25,
        zIndex: 10,
    },
            
    lineLong: {
        animation: 'icon-line-long 0.75s',
    	height: 5,
        backgroundColor: theme.palette.green.main,
        display: 'block',
        borderRadius: 2,
        position: 'absolute',
        right: 8,
        top: 38,
        transform: 'rotate(-45deg)',
        width: 47,
        zIndex: 10,
    },
    iconCircle: {
        top: -4,
        left: -4,
        zIndex: 10,
        width: 80,
        height: 80,
        borderRadius: '50%',
        position: 'absolute',
        boxSizing: 'content-box',
        border: '4px solid rgba(76, 175, 80, .5)',
    },  
    iconFix: {
        top: 8,
        width: 5,
        left: 26,
        zIndex: 1,
        height: 85,
        position: 'absolute',
        transform: 'rotate(-45deg)',
        backgroundColor: 'transparent',
    },

	'@keyframes rotate-circle': {
	    '0%': {
	        transform: 'rotate(-45deg)',
	    },
	    '5%': {
	        transform: 'rotate(-45deg)',
	    },
	    '12%': {
	        transform: 'rotate(-405deg)',
	    },
	    '100%': {
	        transform: 'rotate(-405deg)',
	    }
	},
	'@keyframes icon-line-tip': {
	    '0%': {
	        width: 0,
	        left: 1,
	        top: 19,
	    },
	    '54%': {
	        width: 0,
	        left: 1,
	        top: 19,
	    },
	    '70%': {
	        width: 50,
	        left: -8,
	        top: 37,
	    },
	    '84%': {
	        width: 17,
	        left: 21,
	        top: 48,
	    },
	    '100%': {
	        width: 25,
	        left: 14,
	        top: 45,
	    }
	},

	'@keyframes icon-line-long': {
	    '0%': {
	        width: 0,
	        right: 46,
	        top: 54,
	    },
	    '65%': {
	        width: 0,
	        right: 46,
	        top: 54,
	    },
	    '84%': {
	        width: 55,
	        right: 0,
	        top: 35,
	    },
	    '100%': {
	        width: 47,
	        right: 8,
	        top: 38,
	    }
	}
}));

const SideNav = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.checkIcon}>
				<span className={classes.lineTip}></span>
				<span className={classes.lineLong}></span>
				<div className={classes.iconCircle}></div>
				<div className={classes.iconFix}></div>
			</div>
		</div>
	);
}

SideNav.propTypes = {
    // id: PropTypes.string.isRequired
};

SideNav.defaultProps = {
    className: [],
    color: "primary",
    disabled: false,
    drawerOpen: true,
    size: "medium",
    variant: "permanent"
};

export default SideNav;