import React from 'react';
import clsx from 'classnames';
import get from 'lodash/get';
import {
    FormControl,
    InputLabel,
    Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";
import { getInputHelperText, renderHelperText } from './helpers';

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%'
    },
    margin: {
        margin: theme.spacing(2, 0, 1)
    }
}));

const GuiSelect = (props) => {
    const classes = useStyles();
    const context = React.useContext(FormContext);

    function onChange(e) {
      if(!!props.onChange) {
        props.onChange({id: props.id, value: e.target.value}, context);
      } else {
        context.onChange({id: props.id, value: e.target.value});
      }
    }

    const {
        className,
        errorText,
        formControlProps,
        fullWidth,
        helperText,
        label,
        margin,
        value,
        variant,
        ...restProps
    } = props;

    let inputValue = value;
    if(inputValue === undefined) {
        inputValue = get(context.data, props.id);
    }

    const inputClassName = clsx(
        // {[classes.margin]: margin === 'dense'},
        className,
        // {[classes.fullWidth]: props.fullWidth}
    );

    const { inputHelperText, isError } = getInputHelperText(props.id, context, errorText, helperText);

    return (
        <FormControl
          {...formControlProps}
          className={inputClassName}
          error={isError}
          fullWidth={fullWidth}
          margin={margin}
          variant={variant}
        >
            { !!label && <InputLabel id={`${props.id}-label`}>{label}</InputLabel> }
            <Select
                {...restProps}
                labelId={`${props.id}-label`}
                value={inputValue}
                onChange={onChange}
                label={label}
            >
                {props.children}
            </Select>
            { renderHelperText(inputHelperText, isError) }
        </FormControl>
    )
}

GuiSelect.propTypes = {
    id: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    margin: PropTypes.string,
};

GuiSelect.defaultProps = {
    className: [],
    errorText: '',
    fullWidth: false,
    formControlProps: {},
    helperText: '',
    margin: "normal",
    variant: "outlined"
};

export default GuiSelect;
