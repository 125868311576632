export const APP_INIT = "APP_INIT";

export const APP_HANDLE_ERROR = 'APP_HANDLE_ERROR';
export const APP_LOADED = 'APP_LOADED';

export const CONFIRMATION_CONFIRMED = 'CONFIRMATION_CONFIRMED';
export const CONFIRMATION_UNCONFIRMED = 'CONFIRMATION_UNCONFIRMED';

export const MODAL_HIDE = 'MODAL_HIDE';
export const MODAL_SHOW = 'MODAL_SHOW';

export const SNACKBAR_HIDE = 'SNACKBAR_HIDE';
export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';

export const TABLE_SETTINGS_FAILED = 'TABLE_SETTINGS_FAILED';
export const TABLE_SETTINGS_REQUEST = 'TABLE_SETTINGS_REQUEST';
export const TABLE_SETTINGS_SUCCESS = 'TABLE_SETTINGS_SUCCESS';

export const TABLE_UPDATE_FAILED = 'TABLE_UPDATE_FAILED';
export const TABLE_UPDATE_REQUEST = 'TABLE_UPDATE_REQUEST';
export const TABLE_UPDATE_SUCCESS = 'TABLE_UPDATE_SUCCESS';

export const TABLE_UPDATE_MANUALLY = 'TABLE_UPDATE_MANUALLY';

export const TABLE_UPDATE = 'TABLE_UPDATE';