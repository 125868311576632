import { Avatar, Box, Link, makeStyles, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    app: {
        alignItems: "center",
        backgroundColor: "#FFF",
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.spacing(1),
        color: "#000",
        display: "flex",
        flexDirection: "column",
        height: 100,
        justifyContent: "center",
        margin: theme.spacing(1),
        width: 100,
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
            textDecoration: "none",
        },
    },
    text: {
        marginTop: theme.spacing(1),
        overflow: "hidden",
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: 90,
    },
    inventory: {
        color: theme.palette.getContrastText(theme.palette.green.main),
        backgroundColor: theme.palette.green.main,
    },
    tickets: {
        color: theme.palette.getContrastText(theme.palette.blue.main),
        backgroundColor: theme.palette.blue.main,
    },
}));

function AppAccessList() {
    const applications = useSelector((state) => state.me.me.applications);
    const classes = useStyles();

    const uniqueApplications = useMemo(() => {
        return applications.reduce((prev, item) => {
            if (prev.filter((i) => i.key === item.key).length > 0) {
                return prev;
            }

            return [...prev, item];
        }, []);
    }, [applications]);

    const renderApplication = (item) => {
        return (
            <Link key={item.key} href={item.authUrl} className={classes.app}>
                <Avatar className={classes.inventory}>{item.key}</Avatar>
                <Typography className={classes.text}>{item.name}</Typography>
            </Link>
        );
    };

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
        >
            {uniqueApplications.map((item) => renderApplication(item))}
        </Box>
    );
}

AppAccessList.propTypes = {};

AppAccessList.defaultProps = {};

export default AppAccessList;
