import { merge } from "lodash";
import React from "react";
import { connect } from "react-redux";

let data = {};

export function registerModals(modules) {
    data = merge(data, modules);
}

function Modals(props) {
    const modalsToShow = [];
    for (let modal of props.modals) {
        if (data[modal.id] !== undefined) {
            let View = data[modal.id];

            modalsToShow.push(<View key={modal.id} {...modal.props} />);
        }
    }

    return modalsToShow;
}

function mapStateToProps(state) {
    return {
        modals: state.app.modals,
    };
}

export default connect(mapStateToProps)(Modals);
