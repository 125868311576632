import React, { useState } from 'react';
import clsx from 'classnames';
import '../currency';
import get from 'lodash/get';
import {
    FilledInput,
    FormControl,
    Input,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%'
    }
}));

const GuiColorPicker = (props) => {
    const classes = useStyles();
    const context = React.useContext(FormContext);

    const {
        className,
        fullWidth,
        label,
        value,
        variant,
        ...restProps
    } = props;

    let inputValue = value;
    if(inputValue === undefined) {
        inputValue = get(context.data, props.id);
    }

    if(inputValue === undefined) {
        inputValue = 0;
    }

    const [val, setVal] = useState(inputValue / 100);

    function onChange(e) {
        const targetValue = e.target.value.replace(',', '.');

        if(
            targetValue.length === 0 ||
            /^(\d+)([.]\d{0,2})?$/.test(targetValue)
        ) {
            const value = window.currency(targetValue).intValue;
            context.onChange({id: props.id, value});

            setVal(targetValue);
        }
    }

    function onBlur(e) {
        setVal(window.currency(e.target.value).value);
    }

    const inputClassName = clsx(
        className,
        {[classes.fullWidth]: props.fullWidth}
    );

    let errorText = context.getFieldError(props.id);

    let Component = null;

    if(variant === 'outlined') {
        Component = OutlinedInput;
    } else if(variant === 'filled') {
        Component = FilledInput;
    } else if(variant === 'standard') {
        Component = Input;
    }

    return (
        <FormControl variant={variant} className={inputClassName}>
            { !!label && <InputLabel id={`${props.id}-label`}>{label}</InputLabel> }
            <Component
                {...props}
                {...restProps}
                fullWidth={fullWidth}
                value={val}
                onBlur={onBlur}
                onChange={onChange}
                label={label}
            />
        </FormControl>
    )
}

GuiColorPicker.propTypes = {
    id: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
};

GuiColorPicker.defaultProps = {
    className: [],
    fullWidth: false,
    variant: "outlined"
};

export default GuiColorPicker;
