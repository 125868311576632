import React, { useState } from 'react';
import clsx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';

import {
    FormControl,
    FormHelperText,
    FilledInput,
    Icon,
    InputAdornment,
    InputLabel,
    Input,
    OutlinedInput
} from '@material-ui/core';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";

import DayPicker from '../DayPicker/DayPicker';
import Modal from '../Modal';

const GuiInputDayPicker = (props) => {
    const context = React.useContext(FormContext);
    const [openModal, setOpenModal] = useState(false);

    function onChange(day) {
        if(!!props.onChange) {
            props.onChange({id: props.id, value: moment(day)}, context);
        } else {
            context.onChange({id: props.id, value: moment(day)});
        }
        setOpenModal(false);
    }

    const onClick = (event) => {
      setOpenModal(true);
    }
    const onCloseModal = (event) => {
      setOpenModal(false);
    }

    const { className, fullWidth, helperText, label, margin, value, variant, ...restProps } = props;

    let inputValue = value;
    let selectedDays = [];
    if(inputValue === undefined) {
        inputValue = get(context.data, props.id);
    }
    if(moment.isMoment(inputValue)) {
      selectedDays = [inputValue.toDate()];
      inputValue = inputValue.format(props.format);
    } else if(inputValue !== null && inputValue !== undefined) {
      inputValue = moment(inputValue).format(props.format);
      selectedDays = [inputValue.toDate()];
    } else {
      inputValue = '';
    }

    const inputClassName = clsx(
        'gui-form-input',
        className
    );

    const errorText = context.getFieldError(props.id);
    const isError = errorText.length > 0;
    let inputHelperText = errorText;

    if(!isError && helperText.length > 0) {
        inputHelperText = helperText;
    }

    let Component = null;

    if(variant === 'outlined') {
        Component = OutlinedInput;
    } else if(variant === 'filled') {
        Component = FilledInput;
    } else if(variant === 'standard') {
        Component = Input;
    }

    const renderHelperText = (helperText, errorText) => {
        if(errorText.length > 0) {
            return <FormHelperText error>{errorText}</FormHelperText>;
        }

        if(helperText !== undefined && helperText.length > 0) {
            return <FormHelperText>{helperText}</FormHelperText>;
        }

        return null;
    }

    return (
      <React.Fragment>
        <FormControl
            error={isError}
            className={inputClassName}
            fullWidth={fullWidth}
            margin={margin}
            variant={variant}
        >
            { !!label && <InputLabel id={`${props.id}-label`}>{label}</InputLabel> }
            <Component
                {...restProps}
                fullWidth={fullWidth}
                error={isError}
                value={inputValue}
                onClick={onClick}
                label={label}
                startAdornment={(
                  <InputAdornment position="start">
                    <Icon>event</Icon>
                  </InputAdornment>
                )}
            />
            { renderHelperText(inputHelperText, errorText) }
        </FormControl>
        <Modal
            onClose={onCloseModal}
            open={openModal}
            maxWidth="xs"
        >
            <Modal.Title
                onClose={onCloseModal}
            >
              Choose date
            </Modal.Title>
            <Modal.Content>
              <DayPicker
                month={selectedDays.length > 0 ? selectedDays[0] : null}
                onDayClick={onChange}
                selectedDays={selectedDays}
              />
            </Modal.Content>
        </Modal>
      </React.Fragment>
    )
}

GuiInputDayPicker.propTypes = {
    id: PropTypes.string.isRequired,
    margin: PropTypes.string,
};

GuiInputDayPicker.defaultProps = {
    className: [],
    format: 'DD/MM/YYYY',
    helperText: '',
    margin: "normal",
    variant: "outlined"
};

export default GuiInputDayPicker;
