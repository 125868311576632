import React from "react";

import {
    Box,
    Container,
    Divider,
    Grid,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    actions: {
        alignItems: 'center',
        display: 'flex',
        '& button': {
            padding: 6
        }
    },
    header: {
        marginBottom: theme.spacing(2)
    },
}));

const Page = (props) => {
	const classes = useStyles();

	return (
		<Box
            className={props.className}
        >
            <Container
                className={classes.header}
                disableGutters
                maxWidth={false}
            >
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid
                        item
                    >
                        { !!props.breadcrumps && (
                          <React.Fragment>
                            <Divider orientation="vertical" flexItem />
                            {props.breadcrumps}
                          </React.Fragment>
                        )}

                        { !!props.title && (
                            <Typography className={classes.title} variant="h5">
                                {props.title}
                            </Typography>
                        )}

                        { !!props.actionsLeft && (
                            <div className={classes.actions}>
                                {props.actionsLeft}
                            </div>
                        )}
                    </Grid>
                    <Grid
                        className={classes.actions}
                        item
                    >
                        {props.actions}
                    </Grid>
                </Grid>
            </Container>

            <Container
            	disableGutters
            	maxWidth={false}
            >
            	{props.children}
            </Container>
        </Box>
	)
}

Page.displayName = 'Page';

Page.propTypes = {
	actions: PropTypes.node,
	title: PropTypes.string,
};

Page.defaultProps = {

};

export default Page;
