import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from "lodash/isEqual";
import set from 'lodash/set';

import { Drawer, Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types'
import { useBeforeunload } from 'react-beforeunload';
import { Prompt } from "react-router-dom";

import Button from '../Button';

import FormContext from "./FormContext";

import Checkbox from './Checkbox';
import ColorPicker from './ColorPicker';
import DayPicker from './DayPicker';
import DayRangePicker from './DayRangePicker';
import Input from './Input';
import OpenHours from './OpenHours';
import Phone from './Phone';
import Price from './Price';
import Time from './Time';
import Select from './Select';
import Switch from './Switch';

const useStyles = makeStyles((theme) => ({
	changeDrawerPaper: {
		padding: theme.spacing(0, 2),
		zIndex: theme.zIndex.drawer + 2,
		...theme.mixins.toolbar,
	},
	changeDrawerGrid: {
		...theme.mixins.toolbar,
	},
	changeDrawerGridMain: {
		flex: 1
	},
  	message: {
  		marginBottom: theme.spacing(2)
	},
}));

const Form = forwardRef((props, ref) => {
	const classes = useStyles();
	const [state, setState] = useState({
		changed: false,
		data: prepareData(),
		success: false
	});
	const [orgData, setOrgData] = useState(state.data);

	useBeforeunload(() => {
		if(props.changes && state.changed) {
			return "Your changes are not saved!";
		}

		return;
	});

	useEffect(() => {
		if(state.success && !!props.onSuccess) {
			props.onSuccess();
		}
	}, [state.success]);

	useImperativeHandle(ref, () => ({
		getData() {
			return state.data;
		},
		onChange,
    onSubmit
	}));

  const getParam = (param) => {
    return get(state.data, param);
  }

	function getFieldError(field) {
		if(
			props.errors &&
			props.request.errors !== undefined &&
			props.request.errors[field] !== undefined
		) {
			return props.request.errors[field];
		}

		return '';
	}

	function prepareData() {
		let data = {};

		for(let i in props.fields) {
			const field = props.fields[i];

			let value = get(props.data, i);

			if(value === undefined) {
				if(field.default !== undefined) {
					value = field.default;
				} else {
					value = '';
				}
			}

			data[i] = value;
		}

		return data;
	}

	function renderMessage() {
		if(props.request !== undefined &&
			props.request.message !== undefined  &&
			props.request.message.length > 0
		) {
			let status = "success";

			if(props.request.status >= 400) {
				status = "error";
			}

			return <Alert severity={status} className={classes.message}>{ props.request.message }</Alert>;
		}

		return null;
	}

	function renderDrawer() {
		if(!props.topSave || !state.changed) {
			return null;
		}

		return (
			<Drawer
				anchor="top"
				className={classes.changeDrawer}
				open
				PaperProps={{
					className: classes.changeDrawerPaper
				}}
				variant="permanent"
			>
				<Grid
					container
					className={classes.changeDrawerGrid}
					alignItems="center"
				>
					<Grid
						item
						className={classes.changeDrawerGridMain}
					>

					</Grid>
					<Grid
						item
						className={classes.changeDrawerGridActions}
					>
						<Button
							color="green"
							onClick={onSubmit}
							startIcon={<Icon>save</Icon>}
						>
							Save
						</Button>
					</Grid>
				</Grid>
			</Drawer>
		);
	}

	function onChange(newData) {
		if(!Array.isArray(newData)) {
			newData = [newData];
		}

		let tempData = cloneDeep(state.data);

		for(let item of newData) {
			tempData = set(tempData, item.id, item.value);
		}

		setState({
			...state,
			changed: !isEqual(orgData, tempData),
			data: tempData
		});
	}

	function onSubmit(e) {
		e.preventDefault();

		if(!!props.onSubmit) {
			props.onSubmit(state.data, onSuccess);

		}
	}

	function onSuccess(action) {
		setState({
			...state,
			changed: false,
			success: true
		});
	}

	const context = {
		data: state.data,
    dataAll: props.data,
		getFieldError,
    getParam,
		onChange,
	}

	return (
		<form onSubmit={onSubmit}>
			<Prompt
        		when={props.changes ? state.changed : false}
        		message={location =>
          			`Your changes are not saved!`
        		}
      		/>

			{renderMessage()}
			{renderDrawer()}
			<FormContext.Provider value={context}>
				{ typeof props.children === 'function' ? props.children(context) : props.children }
			</FormContext.Provider>
		</form>
	)
});

Form.Context = FormContext;
Form.Checkbox = Checkbox;
Form.ColorPicker = ColorPicker;
Form.DayPicker = DayPicker;
Form.DayRangePicker = DayRangePicker;
Form.Input = Input;
Form.OpenHours = OpenHours;
Form.Phone = Phone;
Form.Price = Price;
Form.Time = Time;
Form.Select = Select;
Form.Switch = Switch;

Form.displayName = 'Form';

Form.propTypes = {
	changes: PropTypes.bool,
	errors: PropTypes.bool,
	topSave: PropTypes.bool
};

Form.defaultProps = {
	changes: true,
  data: {},
	errors: true,
	topSave: true
};

export default Form;
