import React, { useEffect, useRef, useState } from 'react';
import clsx from 'classnames';
import get from 'lodash/get';
import {
    FormControl,
    FormHelperText,
    FilledInput,
    Icon,
    InputAdornment,
    InputLabel,
    Input,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    OutlinedInput
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";
import { countriesList, countryPhoneCodes } from "../CountriesISO";
import Modal from '../Modal';
import trans from '../translate';
import usePrevious from '../usePrevious';

const useStyles = makeStyles((theme) => ({
    countryCode: {
        cursor: 'pointer'
    },
    flag: {
        marginRight: theme.spacing(1),
        marginTop: -1,
    }
}));

const Phone = (props) => {
    const inputRef = useRef();

    const classes = useStyles();
    const context = React.useContext(FormContext);
    const [openModal, setOpenModal] = useState(false);
    const prevOpen = usePrevious(openModal);

    useEffect(() => {
        if(prevOpen && !openModal && inputRef.current !== undefined) {
            inputRef.current.focus();
        }
    }, [openModal])

    const [search, setSearch] = useState('');

    const {
        className,
        fullWidth,
        helperText,
        label,
        margin,
        value,
        variant,
        ...restProps
    } = props;

    let inputValue = value;
    if(inputValue === undefined) {
        inputValue = get(context.data, props.id);
    }

    let countryCode = '';

    if(inputValue === '' || inputValue === null) {
        countryCode = window.fallback_phone_country_code;
        inputValue = '';
    } else {
        const temp = inputValue.split(' ');

        if(temp.length === 0) {
            countryCode = window.fallback_phone_country_code;
            inputValue = '';
        } else if(temp.length === 1) {
            if(countryPhoneCodes[temp[0].replace('+', '')] === undefined) {
                countryCode = window.fallback_phone_country_code;
            } else {
                countryCode = temp[0];
            }

            inputValue = '';
        } else {
            countryCode = temp[0];
            temp.shift();
            inputValue = temp.join(' ');
        }
    }

    const onChange = (event) => {
        context.onChange({id: props.id, value: countryCode+" "+event.target.value.replace(/[^0-9]/g, '')});
    }
    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    }

    const onClickCountry = (event, item) => {
        context.onChange({id: props.id, value: item.id+" "+inputValue});
        setOpenModal(false);
    }

    const onClickCountryCode = (event) => {
        if(props.disabled !== true) {
            setOpenModal(true)
        }
    }
    const onCloseModal = (event) => {
        setOpenModal(false)
    }

    const renderAdornment = (countryCode) => {
        const className = clsx(
            classes.flag,
            'fflag',
            'ff-md',
            `fflag-${countryPhoneCodes[countryCode.replace('+', '')].toUpperCase()}`
        );

        return (
            <div
                className={classes.countryCode}
                onClick={onClickCountryCode}
            >
                <div className={className} /> {countryCode}
            </div>
        );
    }

    const renderHelperText = (helperText, errorText) => {
        if(errorText.length > 0) {
            return <FormHelperText error>{errorText}</FormHelperText>;
        }

        if(helperText !== undefined && helperText.length > 0) {
            return <FormHelperText>{helperText}</FormHelperText>;
        }

        return null;
    }

    const inputClassName = clsx(
        'gui-form-input',
        className
    );

    const errorText = context.getFieldError(props.id);
    const isError = errorText.length > 0;

    let Component = null;

    if(variant === 'outlined') {
        Component = OutlinedInput;
    } else if(variant === 'filled') {
        Component = FilledInput;
    } else if(variant === 'standard') {
        Component = Input;
    }

    return (
        <React.Fragment>
            <FormControl
                error={isError}
                className={inputClassName}
                fullWidth={fullWidth}
                margin={margin}
                variant={variant}
            >
                { !!label && <InputLabel id={`${props.id}-label`}>{label}</InputLabel> }
                <Component
                    {...restProps}
                    error={isError}
                    inputProps={{
                        pattern: "[0-9]*",
                        type: "tel"
                    }}
                    inputRef={inputRef}
                    label={label}
                    onChange={onChange}                
                    startAdornment={<InputAdornment position="start">{renderAdornment(countryCode)}</InputAdornment>}
                    value={inputValue}
                />
                { renderHelperText(helperText, errorText) }
            </FormControl>
            <Modal
                onClose={onCloseModal}
                open={openModal}
                maxWidth="xs"
            >
                <Modal.Title
                    onClose={onCloseModal}
                >
                    {trans('gui.form.phone.modal.title')}
                </Modal.Title>
                <Modal.Content>
                    <OutlinedInput
                        autoFocus
                        fullWidth
                        onChange={onChangeSearch}
                        placeholder={trans('gui.form.phone.modal.search.placeholder')}
                        startAdornment={<InputAdornment position="start"><Icon>search</Icon></InputAdornment>}
                        value={search}
                    />

                    <List component="nav">
                        { countriesList.map(item => {
                            if(search.length > 0 && item.search.indexOf(search.toLowerCase()) === -1){
                                return null;
                            }

                            return (
                                <ListItem key={`flag-${item.country}`} button onClick={event => onClickCountry(event, item)}>
                                    <ListItemIcon>
                                        <div className={`fflag ff-md fflag-${item.country.toUpperCase()}`} />
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Modal.Content>
            </Modal>
        </React.Fragment>
    );
}

Phone.propTypes = {
    id: PropTypes.string.isRequired,
    margin: PropTypes.string,
};

Phone.defaultProps = {
    className: [],
    margin: "normal",
    variant: "outlined"
};

export default Phone;
