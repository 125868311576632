import React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const AntTab = withStyles((theme) => ({
  root: {
      textTransform: 'none',
      minWidth: 72,
      minHeight: 56,
      marginRight: theme.spacing(2),

      '&:hover': {
          color: theme.palette.green.light,
          opacity: 1,
      },
      '&$selected': {
          color: theme.palette.green.main,
      },
      '&:focus': {
          color: theme.palette.green.light,
      },
  },
  selected: {},
}))((props) => <Tab {...props} />);

function GuiTab(props) {
  const { ...restProps } = props;

  return (
    <AntTab {...restProps} />
  );
}

GuiTab.propTypes = {

};

GuiTab.defaultProps = {

}

export default GuiTab;
