import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

import Tab from "./Tab";

const AntTabs = withStyles((theme) => ({
  root: {
      minHeight: 56
  },
  indicator: {
      backgroundColor: theme.palette.green.main,
  },
}))(Tabs);

function GuiTabs(props) {
  const { children, ...restProps } = props;

  return (
    <AntTabs {...restProps}>
      {children}
    </AntTabs>
  );
}

GuiTabs.Tab = Tab;

GuiTabs.propTypes = {

};

GuiTabs.defaultProps = {

}

export default GuiTabs;
