import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route } from "react-router-dom";

import history from "./historyApp";
import reportWebVitals from "./reportWebVitals";
import store from "./rootStore";
import Routing from "./routing";

// import ErrorBoundary from "ErrorBoundary";

//day picker
import "react-day-picker/lib/style.css";

let theme = createTheme();

//add colors
theme.palette.blue = theme.palette.augmentColor({
    main: "#3498DB",
});
theme.palette.gray = theme.palette.augmentColor({
    main: "#3E3E3F",
});
theme.palette.green = theme.palette.augmentColor({
    main: "#36845B",
});
theme.palette.red = theme.palette.augmentColor({
    main: "#DE3618",
});
theme.palette.yellow = theme.palette.augmentColor({
    main: "#F1C40F",
});

//add other configs
theme.props.MuiButtonBase = {
    disableRipple: true,
};
theme.transitions = {
    ...theme.transitions,
    create: () => "none",
};

theme.sideNav = {
    background: theme.palette.gray.main,
    color: theme.palette.gray.contrastText,
    selectedBg: theme.palette.gray.light,
    width: 270,
};

ReactDOM.render(
    <Router history={history}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Route component={Routing} />
            </ThemeProvider>
        </Provider>
    </Router>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
