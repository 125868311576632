export const ME_MODULE_INITED = 'ME_MODULE_INITED';

export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const ME_FAILED = 'ME_FAILED';
export const ME_REQUEST = 'ME_REQUEST';
export const ME_SUCCESS = 'ME_SUCCESS';

export const ME_LOGOUT_FAILED = 'ME_LOGOUT_FAILED';
export const ME_LOGOUT_REQUEST = 'ME_LOGOUT_REQUEST';
export const ME_LOGOUT_SUCCESS = 'ME_LOGOUT_SUCCESS';