import React, { useEffect, useState } from 'react';

import {
  Grid,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DateUtils } from 'react-day-picker';

import Button from '../Button';
import DayPicker from './DayPicker';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2)
    }
}));

function DayPickerRange(props) {
	const getInitState = (data = {}) => {
		const before = data.before !== undefined ? data.before : null;

    return {
			after: data.after !== undefined ? data.after : null,
			mouseOver: before,
			before
		};
    }

	const [state, setState] = useState(getInitState(props));
    const classes = useStyles();

    // useEffect(() => {
    // 	props.onChange(state.after, state.before);
    // }, [state.after, state.before]);


    useEffect(() => {
    	setState(getInitState(props));
    }, [props.after, props.before]);

    const isSelectingFirstDay = (after, before, day) => {
    	const isBeforeFirstDay = after && DateUtils.isDayBefore(day, after);
    	const isRangeSelected = after && before;
    	return !after || isBeforeFirstDay || isRangeSelected;
	}

    const onClickDay = (day, {selected}) => {
    	const { after, before } = state;
    	if(after && before && day >= after && day <= before) {
      		onClickReset();
			return;
    	}

    	if (isSelectingFirstDay(after, before, day)) {
      		setState({
		        after: day,
		        before: null,
		        mouseOver: null,
		    });
    	} else {
      		setState({
      			...state,
        		before: day,
        		mouseOver: day,
      		});
    	}
    }

    const onClickReset = () => {
    	setState(getInitState());
    }

    const onClickUse = () => {
      props.onChange(moment(state.after), moment(state.before));
    }

    const onMouseOver = day => {
    	const { after, before } = state;
    	if (!isSelectingFirstDay(after, before, day)) {
      		setState({
      			...state,
        		mouseOver: day,
      		});
    	}
  	}

    const renderMessage = () => {
        if(!state.after && !state.before) {
            return "Please select the first day.";
        } else if(state.after && !state.before) {
            return "Please select the last day";
        } else {
            return `Selected from ${moment(state.after).format("DD/MM/YYYY")} to
                ${moment(state.before).format("DD/MM/YYYY")}`;
        }
    }

	return (
		<div className={classes.root}>
			<DayPicker
        month={state.after}
				onDayClick={onClickDay}
				onDayMouseEnter={onMouseOver}
				selectedDays={[
					state.after,
					{
						from: state.after,
						to: state.mouseOver
					}
				]}
			/>

      <Typography align="center">{renderMessage()}</Typography>

      <Grid container direction="row" spacing={1}>
        <Grid item xs={6}>
          <Button
            fullWidth
            onClick={onClickReset}
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="blue"
            disabled={state.after === null || state.before === null}
            fullWidth
            onClick={onClickUse}
          >
            Use
          </Button>
        </Grid>
      </Grid>
		</div>
	);
}

DayPickerRange.propTypes = {

};

DayPickerRange.defaultProps = {
    after: null,
    before: null
};

export default DayPickerRange;
