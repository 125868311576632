export function tableUpdate(state, action) {
	const extraProps = {};

	switch (action.action) {
		case "columns":
			return {
				...state,
				columns: action.data
			};

		case "filter":
			if(state.page !== undefined) {
				extraProps.page = state.page !== undefined ? 1 : undefined;
			}

			return {
				...state,
				filters: {
					...state.filters,
					...action.data,
				},
				...extraProps
			};

		case "page":
			return {
				...state,
				page: action.data
			};

		case "per_page":
			return {
				...state,
				page: 1,
				per_page: action.data
			};

		case "search":
			return {
				...state,
				page: state.page !== undefined ? 1 : undefined,
				search: action.data
			};

		case "sort":
			return {
				...state,
				columns: state.columns.map(item => {
					if(action.data[item.field] !== undefined) {
						return {
							...item,
							sort: action.data[item.field],
							sortable: true
						};
					}

					return {
						...item,
						sortable: false
					};
				}),
				page: state.page !== undefined ? 1 : undefined,
				sort: action.data
			};

		default:
			return state;
	}
}

export class rs {
	static init(state) {
		let requests = {};
		for(let i in state.request)
			requests[i] = f.initState;

		return {
			...state,
			request: requests
		};
	}

	// REQUESTS
	static request(state, action, place) {
		const ret = {
			...state,
			data: {
				...state.data,
				[place]: undefined
			},
			request: {
				...state.request,
				[place]: f.loading()
			}
		}

		if(action.filters !== undefined) {
			ret.filters = {
				...state.filters,
				...action.filters
			};
		}

		return ret;
	}

	// SUCCESS
	static success(state, action, place) {
		return {
			...state,
			request: {
				...state.request,
				[place]: f.clean(action)
			}
		}
	}

	static requestSuccess(state, action, place, dataPlace) {
		if(dataPlace === undefined)
			dataPlace = place;

		const ret = {
			...state,
			data: {
				...state.data,
				[dataPlace]: action.response.data
			},
			request: {
				...state.request,
				[place]: f.clean(action)
			}
		};

		return ret;
	}

	static tableSuccess(state, action) {
		return {
			...state,
            loading: false,
            page: action.response.meta.page,
            rows: action.response.data,
            totalRecords: action.response.meta.total
		}
	}

	// FAILED
	static failed(state, action, place) {
		return {
			...state,
			request: {
				...state.request,
				[place]: f.failed(action)
			}
		}
	}
}

export class f {
	static initState = {
		loading: false,
		message: "",
		errors: {},
		status: 0
	};

	static update(state, action, type) {
		if(action.form === undefined)
			return state.forms;
		
		return {
			...state.forms,
			[action.form]: type(this, action)
		}
	}

	//---------------------
	
	
	static clean(action) {
		const ret = {...this.initState};

		if(action !== undefined) {
			if(action.response !== undefined) {
				if(action.response.message !== undefined) {
					ret.message = action.response.message;
				}
			}

			if(action.status !== undefined) {
				ret.status = action.status;
			}
		}
		
		return ret;
	}

	static failed(action) {
		return {
			...this.initState,
			message: action.message,
			errors: action.errors !== undefined ? action.errors : {},
			status: action.status
		}
	}

	static loading() {
		return {...this.initState, loading: true};
	}
}