import React from 'react';

import Button from '@material-ui/core/Button';
import clsx from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

import createColorStyles, { name } from "../createColorStyles";
import { equals, mergeAll } from "ramda";

const colors = ["blue", "green", "red"];
const defaultColors = ["default", "inherit", "primary", "secondary"];
const variants = ["text", "outlined", "contained"];

const isOutlined = equals(variants[1]);
const isContained = equals(variants[2]);
function template(variant, color, theme) {
  return mergeAll([
    {
      color: theme.palette[color].main
    },
    isContained(variant) && {
      color: theme.palette[color].contrastText,
      backgroundColor: theme.palette[color].main
    },
    isOutlined(variant) && {
      border: "1px solid",
      borderColor: alpha(theme.palette[color].main, 0.5)
    },
    {
      "&:hover": mergeAll([
        {
          backgroundColor: alpha(
            theme.palette[color].main,
            theme.palette.action.hoverOpacity
          )
        },
        isContained(variant) && {
          backgroundColor: theme.palette[color].dark
        },
        isOutlined(variant) && {
          border: "1px solid",
          borderColor: theme.palette[color].main
        }
      ])
    }
  ]);
}

const useStyles = makeStyles(theme => ({
  ...createColorStyles(colors, variants, template, theme),
}));

const StyledButton = withStyles((theme) => ({
  root: {
    textTransform: 'none',
  }
}))(Button);

const GuiButton = (props) => {
    const classes = useStyles();

    const { color, className, loading, ...restProps } = props;

    let extraClass = [];
    let extraProps = {};
    if(defaultColors.indexOf(color) === -1) {
       extraClass.push(classes[name(props.variant, color)]);
    } else {
       extraProps.color = color;
    }

    const classNames = clsx(
        classes.root,
        className,
        extraClass
    );

    let children = props.children;
    let disabled = props.disabled;
    if(loading) {
        const size = {
            large: 26,
            medium: 24,
            small: 22
        };

        children = <CircularProgress size={size[props.size]} />;
        disabled = true;
    }

    return (
        <StyledButton
            {...restProps}
            {...extraProps}
            className={classNames}
            disabled={disabled}
            loading={loading.toString()}
        >
            {children}
        </StyledButton>
    );
}

GuiButton.propTypes = {
    // id: PropTypes.string.isRequired
};

GuiButton.defaultProps = {
    className: [],
    color: "default",
    disabled: false,
    loading: false,
    size: "medium",
    variant: "contained"
};

export default GuiButton;
