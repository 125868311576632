import React, { useState } from 'react';
import clsx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';

import {
    Grid,
    Icon,
    IconButton,
    Typography
} from '@material-ui/core';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";

import Button from '../Button';
import Modal from '../Modal';
import Form from './Form';

const GuiOpenHours = (props) => {
    const context = React.useContext(FormContext);

    const {
      className,
      id,
      interval,
    } = props;

    const onChangeTimeFrom = ({id, value}) => {
      const timeToId = id.replace('from', 'to');
      let timeTo = get(context.data, timeToId);

      if(timeTo === null || timeTo < value) {
        timeTo = value;
      }

      context.onChange([
        {id, value},
        {id: timeToId, value: timeTo}
      ]);
    }

    const onClickAddTimes = (event, day) => {
      const timesId = `${id}.${day}.times`;
      const times = get(context.data, timesId);

      context.onChange([
        {
          id: timesId,
          value: [
            ...times,
            {from: null, to: null}
          ]
        }
      ])
    }

    const onClickRemoveTimes = (event, day, timeIdx) => {
      const timesId = `${id}.${day}.times`;
      const times = get(context.data, timesId);

      context.onChange([
        {
          id: timesId,
          value: times.filter((item, idx) => idx !== timeIdx)
        }
      ])
    }

    const renderDay = (day) => {
      return (
        <Grid key={`day-${day}`} container direction="row" alignItems="flex-start" spacing={1}>
          <Grid item style={{flex: 1}}>
            <Form.Switch
              color="primary"
              id={`${id}.${day}.open`}
              label={day}
            />
          </Grid>
          <Grid item>
            { context.data[id][day].times.map((item, idx) => renderTime(day, item, idx)) }
          </Grid>
        </Grid>
      )
    }

    const renderTime = (day, time, idx) => {
      const disabled = !context.data[id][day].open;
      const timeToMin = {h: 0, m: 0};
      const from = context.data[id][day].times[idx].from;
      if(from !== null) {
        timeToMin.h = Math.floor(from / 60);
        timeToMin.m = from % 60;
      }

      let actions = (
        <IconButton
          disabled={!context.data[id][day].open}
          onClick={event => onClickAddTimes(event, day)}
          size="small"
        >
          <Icon>add</Icon>
        </IconButton>
      );
      if(idx > 0) {
        actions = (
          <IconButton
            disabled={!context.data[id][day].open}
            onClick={event => onClickRemoveTimes(event, day, idx)}
            size="small"
          >
            <Icon>close</Icon>
          </IconButton>
        );
      }

      return (
        <Grid key={`time-${day}-${idx}`} container direction="row" alignItems="center" spacing={0} style={{marginBottom: 4}}>
          <Form.Time
            disabled={disabled}
            formControlProps={{
              size: 'small',
              style: {flex: 1}
            }}
            id={`${id}.${day}.times.${idx}.from`}
            interval={interval}
            margin="none"
            onChange={onChangeTimeFrom}
            output="int"
          />
          <Typography display="inline" style={{margin: "0 8px"}}>to</Typography>
          <Form.Time
            disabled={disabled}
            formControlProps={{
              size: 'small',
              style: {flex: 1}
            }}
            id={`${id}.${day}.times.${idx}.to`}
            interval={interval}
            margin="none"
            min={timeToMin}
            output="int"
          />
          {actions}
        </Grid>
      );
    }

    return (
      <div>
        {['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].map(item => renderDay(item))}
      </div>
    )
}

GuiOpenHours.propTypes = {
    id: PropTypes.string.isRequired,
    margin: PropTypes.string,
};

GuiOpenHours.defaultProps = {
    className: [],
    interval: 1,
    variant: "outlined"
};

export default GuiOpenHours;
