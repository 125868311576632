import React from 'react';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import PropTypes from 'prop-types'

import Content from './Content';
import Header from './Header';



function GuiPaper(props) {
    const { children, ...restProps } = props;

    return (
        <Paper
            {...restProps}
        >
            { children }
        </Paper>
    );
}

GuiPaper.Content = Content;
GuiPaper.Header = Header;

GuiPaper.propTypes = {
    
};

GuiPaper.defaultProps = {
    className: ""
};

export default GuiPaper;