import {
	APP_INIT,
} from "./types";

import { all, put } from "redux-saga/effects";

import { appSagas } from 'app/App/saga';
import { meSagas } from 'app/Me/saga';

export default function* rootSaga() {
	yield all([
		...appSagas,
		...meSagas
	]);

	yield put({type: APP_INIT});
};