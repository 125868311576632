import React from 'react';
import {
	Grid,
	Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'classnames';
import PropTypes from 'prop-types'

import Item from './Item';

const useStyles = makeStyles((theme) => ({
    container: {
        overflow: 'hidden'
    }
}));

function SettingsForm(props) {
	const classes = useStyles();
    const { children, className, ...restProps } = props;

    const containerClassName = clsx(
        classes.container,
        className
    );

    return (
        <Paper
            {...restProps}
            className={containerClassName}
        >
            { children }
        </Paper>
    );
}

SettingsForm.Item = Item;

SettingsForm.propTypes = {
    
};

SettingsForm.defaultProps = {
    className: ""
};

export default SettingsForm;