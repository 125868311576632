import React, { useState } from 'react';
import clsx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';

import {
    FormControl,
    FormHelperText,
    FilledInput,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    Input,
    OutlinedInput
} from '@material-ui/core';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";

import DayPickerRange from '../DayPicker/DayPickerRange';
import Modal from '../Modal';

const GuiInputRangeDate = (props) => {
    const context = React.useContext(FormContext);
    const [openModal, setOpenModal] = useState(false);

    const {
      after,
      before,
      clearData,
      idAfter,
      idBefore,
      className,
      error,
      fullWidth,
      helperText,
      label,
      margin,
      variant,
      ...restProps
    } = props;

    let afterValue = after;
    if(afterValue === undefined) {
      afterValue = get(context.data, props.idAfter);
    }

    if(moment.isMoment(afterValue)) {
    } else if(afterValue !== null && afterValue !== undefined) {
      afterValue = moment(afterValue);
    } else {
      afterValue = null;
    }

    let beforeValue = before;
    if(beforeValue === undefined) {
      beforeValue = get(context.data, props.idBefore);
    }

    if(moment.isMoment(beforeValue)) {
    } else if(beforeValue !== null && beforeValue !== undefined) {
      beforeValue = moment(beforeValue);
    } else {
      beforeValue = null;
    }

    const onChange = (after, before) => {
      if(!!props.onChange) {
        props.onChange([
          {id: idAfter, value: after},
          {id: idBefore, value: before},
        ], context);
      } else {
        context.onChange([
          {id: idAfter, value: after},
          {id: idBefore, value: before},
        ]);
      }
      setOpenModal(false);
    }

    const onClickInput = (event) => {
      setOpenModal(true);
    }
    const onClickReset = (event) => {
      event.stopPropagation();
      onChange(null, null);
    }
    const onCloseModal = (event) => {
      setOpenModal(false);
    }

    const renderModalContent = () => {
      if(!openModal) {
        return null;
      }

      return (
        <DayPickerRange
          after={afterValue !== null ? afterValue.toDate() : null}
          before={beforeValue !== null ? beforeValue.toDate() : null}
          onChange={onChange}
        />
      );
    }

    let inputValue = '';
    if(afterValue !== null && beforeValue !== null) {
      inputValue = `${afterValue.format('DD/MM/YYYY')} - ${beforeValue.format('DD/MM/YYYY')}`;
    }

    const inputClassName = clsx(
        'gui-form-input',
        className
    );

    const errorText = !!context ? context.getFieldError(props.id) : props.error;
    const isError = errorText.length > 0;
    let inputHelperText = errorText;

    if(!isError && helperText.length > 0) {
        inputHelperText = helperText;
    }

    let Component = null;

    if(variant === 'outlined') {
        Component = OutlinedInput;
    } else if(variant === 'filled') {
        Component = FilledInput;
    } else if(variant === 'standard') {
        Component = Input;
    }

    const renderHelperText = (helperText, errorText) => {
        if(errorText.length > 0) {
            return <FormHelperText error>{errorText}</FormHelperText>;
        }

        if(helperText !== undefined && helperText.length > 0) {
            return <FormHelperText>{helperText}</FormHelperText>;
        }

        return null;
    }

    if(clearData && afterValue !== null) {
      restProps.endAdornment = (
        <InputAdornment position="end" >
          <IconButton onClick={onClickReset}>
            <Icon>close</Icon>
          </IconButton>
        </InputAdornment>
      )
    }

    return (
      <React.Fragment>
        <FormControl
            error={isError}
            className={inputClassName}
            fullWidth={fullWidth}
            margin={margin}
            variant={variant}
        >
            { !!label && <InputLabel id={`${props.id}-label`}>{label}</InputLabel> }
            <Component
                {...restProps}
                fullWidth={fullWidth}
                error={isError}
                value={inputValue}
                onClick={onClickInput}
                label={label}
                startAdornment={(
                  <InputAdornment position="start">
                    <Icon>event</Icon>
                  </InputAdornment>
                )}
            />
            { renderHelperText(inputHelperText, errorText) }
        </FormControl>
        <Modal
            onClose={onCloseModal}
            open={openModal}
            maxWidth="xs"
        >
            <Modal.Title
                onClose={onCloseModal}
            >
              Choose date
            </Modal.Title>
            <Modal.Content>
              { renderModalContent() }
            </Modal.Content>
        </Modal>
      </React.Fragment>
    )
}

GuiInputRangeDate.propTypes = {
  clearData: PropTypes.bool,
    idAfter: PropTypes.string.isRequired,
    idBefore: PropTypes.string.isRequired,
    margin: PropTypes.string,
};

GuiInputRangeDate.defaultProps = {
    className: [],
    clearData: false,
    error: '',
    format: 'DD/MM/YYYY',
    helperText: '',
    margin: "normal",
    variant: "outlined"
};

export default GuiInputRangeDate;
