import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'classnames';
import PropTypes from 'prop-types'


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}));

function GuiPaperContent(props) {
	const classes = useStyles();
    const { children, className, ...restProps } = props;

    const rootClassName = clsx(
    	classes.root,
    	className
    );

    return (
        <div
          {...restProps}
          className={rootClassName}
        >
            { children }
        </div>
    );
}

GuiPaperContent.propTypes = {

};

GuiPaperContent.defaultProps = {
    className: ""
};

export default GuiPaperContent;
