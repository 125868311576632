import React from 'react';
import {
	Grid,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    content: {
        borderBottom: '1px solid '+theme.palette.grey[300],
        padding: theme.spacing(2)
    },
    label: {
        fontWeight: 500
    },
    side: {
        backgroundColor: theme.palette.grey[100],
        borderBottom: '1px solid '+theme.palette.grey[300],
        borderRight: '1px solid '+theme.palette.grey[300],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(2)
    }
}));

function SettingsFormItem(props) {
    const classes = useStyles();
    // const { children, ...restProps } = props;

    return (
        <Grid
            container
            alignItems="stretch"
            spacing={0}
        >
            <Grid
                className={classes.side}
                item
                md={4}
                xs={12}
            >
                <Typography
                    className={classes.label}
                    component="strong"
                    display="block"
                >
                    {props.label}
                </Typography>
                { !!props.description && (
                    <Typography
                        variant="caption"
                        display="block"
                    >
                        {props.description}
                    </Typography>
                )}
            </Grid>
            <Grid
                className={classes.content}
                item
                md={8}
                xs={12}
            >
                { props.children }
            </Grid>
        </Grid>
    );
}

SettingsFormItem.propTypes = {
    
};

SettingsFormItem.defaultProps = {
    className: ""
};

export default SettingsFormItem;