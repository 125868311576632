import {
    LOGIN_REQUEST,
    ME_REQUEST,
    ME_LOGOUT_REQUEST
} from "./types";

export const login = (data) => ({
	type: LOGIN_REQUEST,
    data
});

export const logout = () => ({
	type: ME_LOGOUT_REQUEST
});

export const me = () => ({
	type: ME_REQUEST
});