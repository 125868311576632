import { removeDiacritics } from './diacritics';

const currencies = {
	'NOK': 'kr',
  'SEK': 'kr'
};

Number.prototype.format = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ');
};

Number.prototype.price = function(currency, position = 'right', format = 2) {
	let value = this;

	if(isNaN(this)) {
		value = 0;
	}

	value = value / 100;

	if(currency === undefined) {
		return value.format(format);
	}

	if(position === 'right')
		return value.format(format)+" "+currencies[currency];
	else
		return currencies[currency]+" "+value.format(format);
}

String.prototype.removeDiacritics = function() {
	return removeDiacritics(this);
}
