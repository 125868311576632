import get from "lodash/get";

export default function translate(text, tags, hasObject = false) {
	let translate = get(window.translate, text);

	if(translate === undefined || typeof translate !== 'string') {
		return text;
	}

	//if has objects
	if(hasObject) {
		let t = [translate];

		for(let tag of Object.keys(tags)) {
			t = setTags(t, tag, tags[tag]);
		}

		return t;
	}
	
	for(var i in tags) {
		translate = translate.replace(new RegExp(`:${i}`, 'g'), tags[i]);
	}

	// const tags = args[0];
	// for(var i in tags) {
	// 	translate = translate.replace(new RegExp(':'+i, 'g'), tags[i]);
	// }

	return translate;
}

function setTags(arr, tag, value) {
	let nt = [];

	for(let i in arr) {
		if(typeof arr[i] === 'string') {
			if(arr[i].indexOf(`:${tag}`) !== -1) {
				const temp = arr[i].split(`:${tag}`);

				const ret = [];
				const len = temp.length;

				for(let t in temp) {
					ret.push(temp[t]);

					if(t < len - 1) {
						ret.push(value);
					}
				}

				nt = [
					...nt,
					...ret
				];
			} else {
				nt.push(arr[i]);
			}
		} else {
			nt.push(arr[i]);
		}
	}

	return nt;
}