import {
    call,
    fork,
    put,
    race,
    take,
    takeLatest,
} from "@redux-saga/core/effects";
import { request } from "helpers/saga";
import { me as meAction } from "app/Me/actions";

import {
    LOGIN_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    ME_FAILED,
    ME_REQUEST,
    ME_SUCCESS,
    ME_LOGOUT_FAILED,
    ME_LOGOUT_REQUEST,
    ME_LOGOUT_SUCCESS,
} from "./types";
import api from "./api";
import historyApp from "historyApp";

function* authFork() {
    while (true) {
        const result = yield race({
            auth: take(LOGIN_SUCCESS),
            logout: take(ME_LOGOUT_SUCCESS),
        });

        if (result.logout) {
            historyApp.replace("/login");
        } else if (result.auth !== undefined) {
            let pathname = "/";
            if (!!result.auth.response.redirect_url) {
                pathname = result.auth.response.redirect_url;
            }

            if (pathname.indexOf("http") === 0) {
                const tmp = document.createElement("a");
                tmp.href = pathname;

                window.location.href = `${tmp.protocol}//${tmp.host}/auth?uuid=${result.auth.response.uuid}`;
            } else {
                historyApp.replace("/");
                yield put(meAction());
            }
        }
    }
}

function* login(action) {
    const response = yield call(
        request,
        api.login,
        action,
        LOGIN_FAILED,
        LOGIN_SUCCESS,
        "post"
    );

    return response;
}

function* logout(action) {
    return yield call(
        request,
        api.logout,
        action,
        ME_LOGOUT_FAILED,
        ME_LOGOUT_SUCCESS,
        "post"
    );
}

function* me(action) {
    return yield call(request, api.me, action, ME_FAILED, ME_SUCCESS, "get");
}

export const meSagas = [
    takeLatest(LOGIN_REQUEST, login),
    takeLatest(ME_REQUEST, me),
    takeLatest(ME_LOGOUT_REQUEST, logout),
    fork(authFork),
];
