import { map, mergeAll, replace, toUpper, xprod } from "ramda";

const capitalize = replace(/^./, toUpper);

export const name = (variant, color) => `${variant}${capitalize(color)}`;

const createColorStyles = (colors, variants, template, theme) =>
  mergeAll(
    map(
      ([variant, color]) => ({
        [name(variant, color)]: template(variant, color, theme)
      }),
      xprod(variants, colors)
    )
  );

export default createColorStyles;