import React from 'react';
import clsx from 'classnames';
import get from 'lodash/get';
import {
    Switch,
    FormControlLabel
} from '@material-ui/core/';
// import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";

//usage
//
// ===> LIST <===
// <Form.Switch
//     id="horizon_terminate"
//     label="Reload Horizon 2"
//     list={true}
//     name="horizon_terminate[]"
//     value="horizon_terminate2"
// />

const GuiSwitch = (props) => {
    const context = React.useContext(FormContext);
    const { checked, className, label, list, ...restProps } = props;

    function onChange(e) {
        let value = e.target.checked;

        if(list) {
            if(e.target.checked) {
                value = [
                    ...context.data[props.id],
                    props.value
                ]
            } else {
                value = context.data[props.id].filter(item => item !== props.value)
            }
        }

        if(!!props.onChange) {
            props.onChange({id: props.id, value}, context);
        } else {
            context.onChange({id: props.id, value});
        }

    }


    let inputChecked = checked;
    if(inputChecked === undefined) {
        inputChecked = get(context.data, props.id);

        if(list) {
            inputChecked = inputChecked.indexOf(props.value) !== -1;
        }
    }

    const inputClassName = clsx(
        'gui-form-switch',
        className
    );

    // let errorText = context.getFieldError(props.id);

    const node = (
        <Switch
            {...restProps}
            checked={inputChecked}
            onChange={onChange}
        />
    );

    if(label !== undefined) {
        return (
            <FormControlLabel
              className={inputClassName}
              control={node}
              label={label}
            />
        );
    }

    return node;
}

GuiSwitch.propTypes = {
    id: PropTypes.string.isRequired
};

GuiSwitch.defaultProps = {
    className: [],
    list: false
    // variant: "outlined"
};

export default GuiSwitch;
