import {
    LOGIN_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    ME_FAILED,
    ME_LOGOUT_REQUEST,
    ME_REQUEST,
    ME_SUCCESS,
} from "./types";

import { rs } from "helpers/reducers";

function general(
    state = {
        data: null,
        me: null,
        request: {
            login: {},
            logout: {},
            me: {},
        },
    },
    action = {}
) {
    switch (action.type) {
        case "@@INIT":
            return rs.init(state);

        case LOGIN_REQUEST:
            return rs.request(state, action, "login");
        case LOGIN_SUCCESS:
            return rs.success(state, action, "login");
        case LOGIN_FAILED:
            return rs.failed(state, action, "login");

        case ME_LOGOUT_REQUEST:
            return {
                ...state,
                me: null,
            };

        case ME_REQUEST:
            return rs.request(state, action, "me");
        case ME_SUCCESS:
            return {
                ...rs.success(state, action, "me"),
                me: action.response.data,
            };
        case ME_FAILED:
            return rs.failed(state, action, "me");

        default:
            return state;
    }
}

export default general;
