import React from 'react';
import { SvgIcon } from '@material-ui/core';

const LogoIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(0.007448, 0, 0, -0.007448, 1.390323, 24.105562)" stroke="none">
	    <path d="M1283 3221 c-204 -48 -374 -162 -478 -319 -27 -41 -31 -44 -64 -38 -135 24 -257 15 -356 -26 -134 -57 -253 -165 -315 -289 -33 -65 -70 -203 -70 -262 0 -112 65 -283 144 -380 51 -62 166 -149 222 -167 l34 -12 0 -730 c0 -715 0 -731 20 -763 58 -95 230 -162 514 -202 177 -25 807 -25 986 0 277 39 446 104 507 196 l23 34 1 731 0 731 72 34 c151 73 276 238 311 413 20 96 20 130 0 226 -47 231 -232 419 -460 467 -84 18 -147 19 -231 3 -58 -11 -66 -10 -74 5 -6 9 -33 46 -62 81 -173 216 -472 325 -724 267z m276 -196 c128 -30 253 -117 328 -225 17 -25 45 -80 63 -122 l32 -76 72 34 c40 18 102 38 137 43 175 28 352 -66 427 -227 24 -51 27 -69 27 -167 0 -98 -3 -116 -27 -167 -54 -116 -166 -203 -295 -229 l-63 -13 0 -699 0 -699 -22 7 c-201 65 -348 80 -813 80 -465 0 -612 -15 -812 -80 l-23 -7 0 700 0 699 -57 12 c-135 26 -231 99 -290 219 -36 73 -38 80 -38 177 0 97 2 104 38 177 102 207 332 279 554 174 l74 -36 15 38 c65 162 160 276 280 340 115 60 263 77 393 47z m306 -2665 c181 -17 335 -54 335 -81 0 -18 -71 -40 -179 -55 -154 -20 -681 -29 -956 -15 -291 15 -423 39 -409 74 10 26 160 62 314 76 138 12 766 13 895 1z"/>
	    <path d="M1570 1535 c-135 -135 -252 -245 -260 -245 -9 0 -71 56 -140 125 -161 161 -161 161 -278 41 -51 -52 -82 -92 -82 -105 0 -36 428 -481 477 -496 12 -3 33 -1 45 5 13 7 177 166 366 353 294 295 342 346 342 371 0 43 -153 196 -195 196 -25 0 -67 -38 -275 -245z"/>
	  </g>
    </SvgIcon>
  );
}

export default LogoIcon;