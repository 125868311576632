import React from 'react';

import {
    Checkbox,
    Icon,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from '@material-ui/core';

import clx from 'classnames';
import get from 'lodash/get';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

import Paper from '../Paper';

const useStyles = makeStyles((theme) => ({
    paper: {
        overflow: 'hidden'
    },
    root: {
        width: '100%',
    },
    container: {
        width: "100%",
        // marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    noData: {
        alignItems: 'center',
        background: theme.palette.grey['100'],
        color: theme.palette.grey['700'],
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        justifyContent: 'center',
        padding: theme.spacing(8)
    },
    noDataLabel: {
        paddingTop: theme.spacing(1)
    },
    rowLink: {
        cursor: 'pointer'
    },
    rowCollapse: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    simplePaginationCaption: {
        display: 'none'
    },
    simplePaginationInput: {
        display: 'none'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const LoaderCell = withStyles((theme) => ({
    root: {
        padding: 0,
    }
}))(TableCell);

const NoRowsCell = withStyles((theme) => ({
    root: {
        padding: theme.spacing(8),
        textAlign: 'center'
    }
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: 700
    },
    body: {
        fontSize: 14,
    },
    stickyHeader: {
        background: '#FFF'
    }
}))(TableCell);

const CheckboxTheadCell = withStyles((theme) => ({
    root: {
        position: 'sticky',
        left: 0,
        width: 48,
        zIndex: 3
    }
}))(TableCell);

const CheckboxCell = withStyles((theme) => ({
    root: {
        // background: 'white',
        position: 'sticky',
        left: 0,
        width: 48,
        // zIndex: 3
    }
}))(TableCell);


const GuiTable = (props) => {
    const classes = useStyles();

    const [selected, setSelected] = React.useState([]);

    const clickRow = !!props.onClickRow;
    const rowCount = 4;
    const numSelected = selected.length;


    const onChangeSelect = (event, idx) => {
        event.stopPropagation();
        console.log(event, idx);
    }

    const onClickRow = (event, item) => {
        if(clickRow) {
            props.onClickRow(event, item);
        }
    }

    const onClickSelectAll = (event) => {

    }

    const onChangePage = (event, page) => {
        if(!!props.tableUpdate) {
            props.tableUpdate(props.id, 'page', page + 1);
        }
    }

    const onChangeRowsPerPage = (event) => {
        if(!!props.tableUpdate) {
            props.tableUpdate(props.id, 'per_page', parseInt(event.target.value, 10));
        }
    }

    const createSortHandler = (event, item) => {
        let way = item.sort;
        if(item.sortable) {
            way = way === 'asc' ? 'desc' : 'asc';
        }

        props.tableUpdate(props.id, 'sort', {[item.field]: way});
    }

    const getColumnsLength = () => {
        if(props.selection) {
            return props.columns.length + 1;
        }

        return props.columns.length;
    }

    const renderHead = () => {
        return props.columns.map((item, idx) => {
            let headName = item.headName;

            if(item.renderHead !== undefined) {
                headName = item.renderHead(
                    item
                );
            }

            return (
                <StyledTableCell
                    key={`gui-head-cell-${idx}`}
                    {...item.cellProps}
                    width={item.width}
                >
                    { item.sortable !== undefined ? (
                        <TableSortLabel
                            active={item.sortable}
                            direction={item.sort}
                            onClick={(event) => createSortHandler(event, item)}
                        >
                            {headName}
                        </TableSortLabel>
                    ) : (headName) }
                </StyledTableCell>
            )
        });
    }

    const renderFoot = () => {
      return props.columns.map((item, idx) => {
          let headName = item.headName;

          if(item.renderHead !== undefined) {
              headName = item.renderHead(
                  item
              );
          }

          return (
              <StyledTableCell
                  key={`gui-foot-cell-${idx}`}
                  {...item.cellProps}
                  width={item.width}
              >
                  (headName)
              </StyledTableCell>
          )
      });
  }

    const renderHeader = () => {
        if(
            props.title === undefined &&
            props.actions === undefined &&
            props.actionsLeft === undefined
        ) {
            return null;
        }

        return (
            <Paper.Header
                title={props.title}
                actions={props.actions}
                actionsLeft={props.actionsLeft}
            />
        );
    }

    const renderLoading = () => {
        if(!props.loading) {
            return null;
        }

        return (
            <TableRow>
                <LoaderCell colSpan={getColumnsLength()}>
                    <LinearProgress />
                </LoaderCell>
            </TableRow>
        );
    }

    const renderNoData = () => {
        if(props.rows.length > 0) {
            return null;
        }

        return (
            <div className={classes.noData}>
                <Icon fontSize="large">inbox</Icon>
                <div className={classes.noDataLabel}>No Data</div>
            </div>
        );
    }

    const renderPagination = () => {
        if(props.page === undefined) {
            return null;
        }

        let classesObj = {};
        if(props.paginationType === 'simple') {
            classesObj = {
                caption: classes.simplePaginationCaption,
                input: classes.simplePaginationInput
            }
        }

        return (
            <TablePagination
                classes={classesObj}
                component="div"
                labelRowsPerPage=""
                rowsPerPageOptions={props.rowsPerPageOptions} //, { label: 'All', value: -1 }]}
                count={props.totalRecords}
                rowsPerPage={props.per_page}
                page={props.page - 1}
                SelectProps={{
                    native: true,
                }}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
            />
        );
    }

    const renderSelectionBody = (idx) => {
        if(!props.selection) {
            return null;
        }

        return (
            <CheckboxCell padding="checkbox">
                <Checkbox
                    checked={false}
                    onChange={(event) => onChangeSelect(event, idx)}
                />
            </CheckboxCell>
        );
    }

    const renderSelectionHead = () => {
        if(!props.selection) {
            return null;
        }

        return (
            <CheckboxTheadCell padding="checkbox">
                <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onClickSelectAll}
                />
            </CheckboxTheadCell>
        );
    }

    const renderCell = (column, data, idx, cIdx) => {
        if(column.renderCell !== undefined) {
            return column.renderCell(
                data,
                `gui-body-row-${idx}-cell-${cIdx}`,
                column,
                idx
            );
        }

        return (
            <TableCell
                key={`gui-body-row-${idx}-cell-${cIdx}`}
                {...column.cellProps}
            >
                {get(data, column.field)}
            </TableCell>
        );
    }

    const renderRows = () => {
        if(props.rows.length === 0) {
            return null;
        }

        return props.rows.map((item, idx) => {
            return (
                <TableRow
                    key={`gui-body-row-${idx}`}
                    className={clickRow ? classes.rowLink : ''}
                    hover
                    onClick={(event) => onClickRow(event, item)}
                >
                    { renderSelectionBody(idx) }
                    { props.columns.map((column, cIdx) => renderCell(column, item, idx, cIdx)) }
                </TableRow>
            );
        });
    }

    const renderRowsWithCollapse = () => {
        if(props.rows.length === 0) {
            return null;
        }

        const className = clx(
          {[classes.rowLink]: !!clickRow },
          classes.rowCollapse
        )

        return props.rows.map((item, idx) => {
            return (
                <React.Fragment
                  key={`gui-body-row-${idx}`}
                >
                  <TableRow
                      className={className}
                      hover
                      onClick={(event) => onClickRow(event, item)}
                  >
                      { renderSelectionBody(idx) }
                      { props.columns.map((column, cIdx) => renderCell(column, item, idx, cIdx)) }
                  </TableRow>
                  { props.renderCollapseRow(item, idx) }
                </React.Fragment>
            );
        });
    }

    const renderTableHead = () => {
        if(!props.tableHead) {
            return null;
        }

        return (
            <TableHead>
                <TableRow>
                    { renderSelectionHead() }
                    { renderHead() }
                </TableRow>
            </TableHead>
        );
    }

    const renderTableFooter = () => {
      if(!!props.renderTableFooter) {
        return props.renderTableFooter(renderSelectionHead);
      }

      // if(!props.tableFooter) {
      //   return null;
      // }

      // return (
      //   <TableFooter>
      //     <TableRow>
      //       { renderSelectionHead() }
      //       { renderFoot() }
      //     </TableRow>
      //   </TableFooter>
      // )
    }

    let tableContainerStyle = {};
    if(props.maxHeight > 0) {
        tableContainerStyle.maxHeight = props.maxHeight;
    }

    //to make scrollable table need to add style={{width: 1868}} to Table

    const tableContainerClassName = [
        classes.container,
        ...props.className
    ].join(' ');

    const renderRowsFunc = !props.renderCollapseRow ? renderRows : renderRowsWithCollapse;

    return (
        <Paper
            className={classes.paper}
        >
            { renderHeader() }
            <TableContainer className={tableContainerClassName} style={tableContainerStyle}>
                <Table
                    size={props.size}
                    stickyHeader
                >
                    { renderTableHead() }
                    <TableBody>
                        { renderLoading() }
                        { renderRowsFunc() }
                    </TableBody>
                    { renderTableFooter() }
                </Table>
            </TableContainer>
            { renderNoData() }
            { renderPagination() }
        </Paper>
    );
}

GuiTable.TableCell = StyledTableCell;

GuiTable.propTypes = {
    columns: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    maxHeight: PropTypes.number,
    onClickRow: PropTypes.func,
    paginationType: PropTypes.string,
    renderCollapseRow: PropTypes.func,
    rows: PropTypes.array.isRequired,
    rowsPerPageOptions: PropTypes.array,
    selection: PropTypes.bool,
    tableFooter: PropTypes.bool,
    tableHead: PropTypes.bool
    // id: PropTypes.string.isRequired
};

GuiTable.defaultProps = {
    className: [],
    columns: [],
    loading: false,
    maxHeight: 0,
    paginationType: 'normal',
    rows: [],
    rowsPerPageOptions: [5, 10, 25],
    selection: false,
    size: 'medium',
    tableFooter: false,
    tableHead: true
};

export default GuiTable;
