import {
	APP_INIT,
	APP_LOADED,

	MODAL_HIDE,
	MODAL_SHOW,


} from "types";

import { rs } from "helpers/reducers";

function general(
	state = {
		loaded: false,
		modals: [],
        request: {}
	}, action = {}) {
	
	switch(action.type) {
		case APP_INIT:
			return rs.init(state);

		case APP_LOADED:
			return {
				...state,
				loaded: true
			}

		case MODAL_HIDE:
			return {
				...state,
				modals: state.modals.filter(modal => modal.id !== action.id)
			};

		case MODAL_SHOW:
			return {
				...state,
				modals: [
					...state.modals,
					{
						id: action.id,
						props: action.props
					}
				]
			};

		default: return state;
	}
}

export default general;
