import React from "react";

import {
    Dialog,
    Icon,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({

}));

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingRight: 52
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const ModalTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>

            { !!onClose && (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Icon>close</Icon>
                </IconButton>
            )}
        </DialogTitle>
    );
});

const ModalContent = withStyles((theme) => ({
    // root: {
    //     padding: theme.spacing(0, 2, 2),
    // },
}))(DialogContent);

const ModalActions = withStyles((theme) => ({
    // root: {
    //     margin: 0,
    //     padding: theme.spacing(1),
    // },
}))(DialogActions);

const Modal = (props) => {
	const classes = useStyles();
    const theme = useTheme();

    const { children, fullScreen, responsive, ...other } = props;

    let fullScreenProp = false;
    if(!!fullScreen) {
        fullScreenProp = fullScreen;
    } else if(props.responsive) {
        fullScreenProp = useMediaQuery(theme.breakpoints.down('xs'));
    }

	return (
		<Dialog
            {...other}
            fullScreen={fullScreenProp}
        >
            {children}
        </Dialog>
	)
}

Modal.Title = ModalTitle;
Modal.Content = ModalContent;
Modal.Actions = ModalActions;

Modal.displayName = 'Modal';

Modal.propTypes = {
	actions: PropTypes.node,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    responsive: PropTypes.bool,
	title: PropTypes.string,
};

Modal.defaultProps = {
    fullWidth: true,
    maxWidth: 'md',
	open: false,
    responsive: true
};

export default Modal;
