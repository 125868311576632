import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clx from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        background: 'rgba(255,255,255,.25)',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 1
    },
    global: {
      position: 'fixed',
    },
    content: {
      position: 'absolute'
    }
}));

const LoaderGlobal = (props) => {
    const classes = useStyles();

    const className = clx(
        classes.root,
        {[classes.content]: props.type === 'content'},
        {[classes.global]: props.type === 'global'},
        [...props.className]
    );

    return (
        <div className={className}>
          <CircularProgress color={props.color} />
        </div>
    );
}

LoaderGlobal.propTypes = {
    // id: PropTypes.string.isRequired
};

LoaderGlobal.defaultProps = {
    className: [],
    color: "primary",
    type: "global"
};

export default LoaderGlobal;
