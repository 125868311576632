import { delay, put, take, takeLatest } from "@redux-saga/core/effects";

import { me } from "app/Me/actions";
import { ME_SUCCESS } from "app/Me/types";
import { APP_INIT, APP_LOADED } from "types";

function* initApp() {
    yield put(me());

    yield take(ME_SUCCESS);

    yield delay(500);
    yield put({ type: APP_LOADED });
}

export const appSagas = [
    // takeLatest(APP_HANDLE_ERROR, handleAppError),
    takeLatest(APP_INIT, initApp),
];
