import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker';
import Helmet from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    next: {
        cursor: 'pointer',
        fontSize: theme.spacing(4),
        position: 'absolute',
        right: 12
    },
    prev: {
        cursor: 'pointer',
        fontSize: theme.spacing(4),
        left: 12,
        position: 'absolute'
    }
}));

function NavbarElement({
    onPreviousClick,
    onNextClick,
    className,
}) {
    const classes = useStyles();

    return (
        <div className={className}>
            <Icon className={classes.prev} onClick={() => {onPreviousClick()}}>navigate_before</Icon>
            <Icon className={classes.next} onClick={() => {onNextClick()}}>navigate_next</Icon>
        </div>
    );
}

function GuiDayPicker(props) {
    const { numberOfMonths, ...restProps } = props;

    return (
        <React.Fragment>
            <Helmet>
                <style>{`
                    .DayPicker {
                        margin-top: 16px;
                        width: 100%;
                    }
                    .DayPicker-wrapper {
                        outline: none;
                    }
                    .DayPicker-Day {
                        border-radius: 0;
                        outline: none;
                    }
                    .DayPicker-Day--outside {
                      visibility: hidden;
                    }
                    .DayPicker-Month {
                        margin: 0 6px;
                        width: calc(${100 / numberOfMonths}% - 12px);
                    }
                    .DayPicker-Month .DayPicker-Caption {
                        text-align: center;
                    }
                `}
                </style>
            </Helmet>
            <DayPicker
                firstDayOfWeek={1}
                fixedWeeks
                navbarElement={<NavbarElement />}
                numberOfMonths={numberOfMonths}
                {...restProps}
            />
        </React.Fragment>
    );
}

GuiDayPicker.propTypes = {
    numberOfMonths: PropTypes.number,
};

GuiDayPicker.defaultProps = {
    numberOfMonths: 1
};

export default GuiDayPicker;
