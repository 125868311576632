import { AppBar, LinearProgress, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import { LogoIcon } from 'gastro-ui-v3';
import React from 'react';
import { connect } from "react-redux";

import AppAccessList from 'app/Me/components/AppAccessList';
import AppBarMe from 'app/Me/components/AppBarMe';
import Modals from 'modals';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        background: theme.palette.green.main,
        flexGrow: 1,
        zIndex: theme.zIndex.drawer + 1,

        [theme.breakpoints.up("lg")]: {
            marginLeft: theme.sideNav.width,
            width: '100%'
        }
    },
    appBarExtra: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        overflowX: 'hidden',
        padding: 0,
    },
    loader: {
        alignItems: 'center',
        background: `linear-gradient(160deg, ${theme.palette.green.main} 0%, ${theme.palette.blue.main} 100%)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%'
    },
    loaderAppName: {
        marginBottom: theme.spacing(2),
    },
    loaderLogo: {
        color: theme.palette.green.main,
        fontSize: 80,
        margin: theme.spacing(2, 0, 2),
        position: 'relative',
    },
    loaderLoader: {
        background: 'none',
        height: 'auto',
        marginTop: theme.spacing(2),
        position: 'relative',
        width: 'auto'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        justifyContent: 'center',
    },
    logo: {
        marginRight: theme.spacing(1)/2,
        position: 'relative',
        top: -2
    },
    menuButton: {
        display: 'none',
        marginRight: theme.spacing(1),

        [theme.breakpoints.down("md")]: {
            display: 'inline'
        }
    },
    paper: {
		// padding: theme.spacing(2, 4, 2, 4),
		maxWidth: 250,
        overflow: 'hidden',
        textAlign: 'center',
		width: '100%'
	},
}));

function App(props) {
	const classes = useStyles();

    // const [mobileOpen, setMobileOpen] = React.useState(false);

    // const clickDrawerToggle = () => {
    //     setMobileOpen(!mobileOpen);
    // };

    if(!props.loaded) {
        return (
            <div className={classes.loader}>
                <Paper className={classes.paper}>
                    <LinearProgress />
                    <LogoIcon className={classes.loaderLogo} />
                    <Typography variant="h5" className={classes.loaderAppName}>Accounts</Typography>
                </Paper>
            </div>
        );
    }

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    {/* <IconButton
                        className={classes.menuButton}
                        edge="start"
                        color="inherit"
                        onClick={clickDrawerToggle}
                    >
                        <Icon>menu</Icon>
                    </IconButton> */}

                    <div className={classes.logoContainer}>
                        <LogoIcon className={classes.logo} />
                        <Typography variant="h6" noWrap>
                            Gastro<strong>Planner</strong>
                        </Typography>
                    </div>
                    <div className={classes.appBarExtra} />

                    <AppBarMe />
                </Toolbar>
            </AppBar>

            <main className={classes.content}>
                <Toolbar />

                <AppAccessList />

                {/* <Switch>
                    <Route path={urls.Customer} component={modules.Customer} />
                    <Route path={urls.CustomerGroups} component={modules.CustomerGroups} />
                    <Route path={urls.Dashboard} component={modules.Dashboard} />
                    <Route path={urls.Location} component={modules.Location} />
                    
                    <Modules
                        key="app-routing"
                        place="app.routing"
                    />
                </Switch> */}
            </main>

            <Modals />
        </>
    );
}

function mapStateToProps(state) {
	return {
		loaded: state.app.loaded
	}
}

export default connect(mapStateToProps)(App);