import React, { useEffect, useRef, useState } from "react";

import {
    Icon,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

import usePrevious from '../usePrevious';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFF',
        // margin: theme.spacing(0, 1, 0, 2),
        paddingRight: 0,
    },
    searchIcon: {
        color: theme.palette.grey[400]
    },
    activeButton: {
        // marginLeft: theme.spacing(1),
    }
}));

const Search = (props) => {
    const classes = useStyles();
    const inputEl = useRef(null);
    let timeout = null;

    const [searchOpen, setSearchOpen] = useState(props.value.length > 0);
    const [value, setValue] = useState(props.value);
    const prevValue = usePrevious(value);

    useEffect(() => {
        clearTimeout(timeout);

        if(prevValue === undefined || prevValue === value) {
            return;
        }

        if(value.length > 0) {
            timeout = setTimeout(() => {
                props.onChange(value);
            }, 500);
        } else {
            props.onChange(value);
        }

        return () => clearTimeout(timeout);
    }, [value])

    const onBlur = (event) => {
        if(props.value.length === 0) {
            setSearchOpen(false);
        }
    }

    const onChange = (event) => {
        setValue(event.target.value)
    }

    const onClickClear = (event) => {
        setValue("");
        if(inputEl.current !== null) {
            inputEl.current.focus();
        }
    }

    if(searchOpen || props.open) {
        return (
            <OutlinedInput
                autoFocus={searchOpen}
                classes={{
                    root: classes.root
                }}
                endAdornment={
                    props.value.length > 0 && (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={onClickClear}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </InputAdornment>
                    )
                }
                onBlur={onBlur}
                onChange={onChange}
                margin="dense"
                placeholder="Search..."
                inputRef={inputEl}
                startAdornment={
                    <InputAdornment position="start">
                        <Icon className={classes.searchIcon}>search</Icon>
                    </InputAdornment>
                }
                value={value}
                variant="outlined"
            />
        )
    }

    return (
        <IconButton
            className={classes.activeButton}
            onClick={event => setSearchOpen(true)}
        >
            <Icon>search</Icon>
        </IconButton>
    );
}

Search.displayName = 'Search';

Search.propTypes = {
    onChange: PropTypes.func.isRequired,
    open: PropTypes.bool,
    value: PropTypes.string.isRequired
};

Search.defaultProps = {
    open: false
};

export default Search;