import React from "react";

import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Button, Form } from "gastro-ui-v3";
import { connect } from "react-redux";

import { login } from "../actions";
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    action: {
        color: theme.palette.blue.dark,
        textAlign: "right",
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    paper: {
        padding: theme.spacing(2, 2, 1, 2),
        maxWidth: 360,
        width: "100%",
    },
    root: {
        alignItems: "center",
        background: `linear-gradient(160deg, ${theme.palette.green.main} 0%, ${theme.palette.blue.main} 100%)`,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
    },
}));

function LoginPage(props) {
    const classes = useStyles();

    const renderFormContent = (context) => {
        return (
            <div className={classes.form}>
                <Typography align="center" variant="h5" gutterBottom>
                    Login
                </Typography>

                <Form.Input id="username" label="Email" type="email" />

                <Form.Input id="password" label="Password" type="password" />

                <Button
                    className={classes.button}
                    color="green"
                    disabled={!context.data.username || !context.data.password}
                    loading={props.loginRequest.loading || props.meRequest}
                    size="large"
                    type="submit"
                >
                    Login
                </Button>

                {/* <Box display="flex">
					<Box flex="1"></Box>
					<Link to="/forgot-password" className={classes.action}>
						<Typography gutterBottom>Forgot password?</Typography>
					</Link>
				</Box> */}
            </div>
        );
    };

    return (
        <Box className={classes.root}>
            <Paper className={classes.paper}>
                <Form
                    changes={false}
                    fields={{
                        username: {},
                        password: {},
                    }}
                    request={props.loginRequest}
                    onSubmit={(data) => props.login(data)}
                    topSave={false}
                >
                    {renderFormContent}
                </Form>
            </Paper>
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        meRequest: state.me.request.me.loading,
        loginRequest: state.me.request.login,
    };
}

export default connect(mapStateToProps, {
    login,
})(LoginPage);
