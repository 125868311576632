import React from 'react';
import clsx from 'classnames';
import get from 'lodash/get';
import {
    Checkbox,
    FormControlLabel
} from '@material-ui/core/';
// import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";


// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         flexDirection: 'column',
//         flexWrap: 'wrap',
//     },
//     margin: {
//         margin: theme.spacing(1),
//     },
//     withoutLabel: {
//         marginTop: theme.spacing(3),
//     },
//     textField: {
//         width: '25ch',
//     },
// }));

//usage
//
// ===> LIST <===
// <Form.Checkbox
//     id="horizon_terminate"
//     label="Reload Horizon 2"
//     list={true}
//     name="horizon_terminate[]"
//     value="horizon_terminate2"
// />

const GuiCheckbox = (props) => {
    const context = React.useContext(FormContext);
    const { checked, className, label, list, ...restProps } = props;

    function onChange(e) {
        let value = e.target.checked;
        if(list) {
            if(e.target.checked) {
                value = [
                    ...context.data[props.id],
                    props.value
                ]
            } else {
                value = context.data[props.id].filter(item => item !== props.value)
            }
        }

        context.onChange({id: props.id, value});
    }


    let inputChecked = checked;
    if(inputChecked === undefined) {
        inputChecked = get(context.data, props.id);

        if(list) {
            inputChecked = inputChecked.indexOf(props.value) !== -1;
        }
    }

    const inputClassName = clsx(
        'gui-form-checkbox',
        [...className]
    );

    let errorText = context.getFieldError(props.id);

    const node = (
        <Checkbox
            {...restProps}
            checked={inputChecked}
            onChange={onChange}
        />
    );

    if(label !== undefined) {
        return (
            <FormControlLabel
                control={node}
                label={label}
            />
        );
    }

    return node;
}

GuiCheckbox.propTypes = {
    id: PropTypes.string.isRequired
};

GuiCheckbox.defaultProps = {
    className: [],
    list: false
    // variant: "outlined"
};

export default GuiCheckbox;
