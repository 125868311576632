import React, { useEffect, useRef } from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Form } from 'gastro-ui-v3';
import { connect } from "react-redux";

import {
	// forgotPassword
} from "../actions";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	action: {
		color: theme.palette.blue.dark,
		textAlign: 'right'
	},
	button: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column'
	},
	paper: {
		padding: theme.spacing(2, 2, 1, 2),
		maxWidth: 360,
		width: '100%'
	},
	root: {
  		alignItems: 'center',
    	background: `linear-gradient(160deg, ${theme.palette.green.main} 0%, ${theme.palette.blue.main} 100%)`,
    	display: 'flex',
    	flexDirection: 'column',
    	height: '100vh',
    	justifyContent: 'center'
	},
}));

function ForgotPasswordPage(props) {
	const classes = useStyles();
	const formRef = useRef();

	let tenantUid = localStorage.getItem('tenant_uid');
	if(tenantUid === null) {
		tenantUid = '';
	}

	useEffect(() => {
		if(props.request.status === 200 && formRef.current !== undefined) {
			formRef.current.onChange([{id: 'email', value: ''}]);
		}
	}, [
        props.request.status
    ]);

	const renderFormContent = (context) => {
		let autoFocus = 'tenant_uid';
		if(tenantUid !== '') {
			autoFocus = 'email';
		}

		return (
			<div className={classes.form}>
				<Typography align="center" variant="h5" gutterBottom>
					Forgot Password
				</Typography>
				<Form.Input
					autoFocus={autoFocus === 'tenant_uid'}
					id="tenant_uid"
					label="Restaurant ID"
					type="text"
				/>

				<Form.Input
					autoFocus={autoFocus === 'email'}
					id="email"
					label="Email"
					type="email"
				/>

				<Button
					className={classes.button}
					color="green"
					disabled={!context.data.tenant_uid || !context.data.email }
					loading={ props.request.loading }
					size="large"
					type="submit"
				>
					Reset password
				</Button>

				<Box display="flex">
					<Box flex="1"></Box>
					<Link to="/login" className={classes.action}>
						<Typography gutterBottom>Back to login</Typography>
					</Link>
				</Box>
			</div>
		);
	}

    return (
        <Box className={classes.root}>
        	<Paper className={classes.paper}>
				<Form
					changes={false}
					fields={{
						email: {},
						tenant_uid: {
							default: tenantUid
						}
					}}
					ref={formRef}
					request={props.request}
					onSubmit={data => props.forgotPassword(data)}
					topSave={false}
				>
					{ renderFormContent }
				</Form>
			</Paper>
        </Box>
    );
}

function mapStateToProps(state) {
	return {
		request: state.auth.request.forgotPassword
	}
}

export default connect(mapStateToProps, {
	// forgotPassword
})(ForgotPasswordPage);