import {
    TableCell
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const TableCellActions = withStyles((theme) => ({
    root: props => ({
        padding: props.noPadding ? 0 : theme.spacing(2, 0),
        textAlign: 'right',

        '& > button': {
      		marginRight: theme.spacing(1),
        }
    })
}))(TableCell);

export default TableCellActions;