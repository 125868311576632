import axios from "axios";
import { call, cancelled, put } from "redux-saga/effects";

export function* handleError(err, type, form, extras) {
    //network error
    if (err.response === undefined) {
        console.log("Something went wrong");

        // store.dispatch(alert('Something went wrong', {type: "danger"}));
        yield put({ type, form, status: 500 });

        return 500;
    } else {
        if (err.response.status === 401) {
            yield put({
                type,
            });
            return 401;
        }

        if (err.response.status === 403) {
            console.log("You dont have permission to this content");
            return 403;
        }

        yield put({
            type,
            form,
            errors: err.response.data.errors,
            message: err.response.data.message,
            status: err.response.status,
            extras,
        });

        // //not found item in storage
        // if(err.response.status === 404) {
        // 	// store.dispatch(alert(trans('rapp.alert.not_found_item'), {type: "danger"}));

        // 	//uncomment
        // 	// console.log(err);
        // 	// history.push(url);
        // }

        return err.response.status;
    }
}

export function* request(
    endpoint,
    action,
    typeFailed,
    typeSuccess,
    type = "get"
) {
    const cancelSource = axios.CancelToken.source();

    let config = action.config;
    if (config === undefined) {
        config = {};
    }

    let data = action.data;
    if (data === undefined) {
        data = {};
    }

    let url = action.url;
    if (url === undefined) {
        url = {};
    }

    try {
        let response = null;

        if (type === "get" || type === "delete") {
            response = yield call(
                endpoint,
                {
                    ...config,
                    cancelToken: cancelSource.token,
                },
                url
            );
        } else if (type === "post" || type === "put") {
            response = yield call(
                endpoint,
                data,
                {
                    ...config,
                    cancelToken: cancelSource.token,
                },
                url
            );
        }

        if (typeSuccess !== undefined) {
            yield put({
                type: typeSuccess,
                response: response.data,
                status: response.status,
            });
        }

        if (!!action.onSuccess) {
            action.onSuccess(response);
        }

        if (response.status === 204) {
            return { success: true, response };
        }

        return { success: response.data, response };
    } catch (err) {
        yield call(handleError, err, typeFailed);

        if (!!action.onFailed) {
            action.onFailed(err.response);
        }

        return { failed: err };
    } finally {
        if (yield cancelled()) {
            cancelSource.cancel();
        }
    }
}
