import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import App from "app/App";
import ForgotPasswordPage from "app/Me/pages/ForgotPasswordPage";
import LoginPage from "app/Me/pages/LoginPage";
import NewPasswordPage from "app/Me/pages/NewPasswordPage";

const routing = (props) => {
    if (!props.isAuth) {
        return (
            <Switch>
                <Route path="/login" exact component={LoginPage} />
                <Route
                    path="/forgot-password"
                    exact
                    component={ForgotPasswordPage}
                />
                <Route
                    path="/new-password/:token/:tenantId"
                    exact
                    component={NewPasswordPage}
                />
                <Route path="*" component={LoginPage} />
            </Switch>
        );
    }

    return <Route path="/" component={App} />;
};

function mapStateToProps(state) {
    return {
        isAuth: state.me.me !== null,
    };
}

export default connect(mapStateToProps)(routing);
