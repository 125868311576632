import React from 'react';
import {
  FormHelperText
} from '@material-ui/core';

export const renderHelperText = (text, isError) => {
  if(text.length === 0) {
    return null;
  }

  return (
    <FormHelperText
      error={isError}
    >
      {text}
    </FormHelperText>
  );
}

export const getInputHelperText = (id, context, errorText, helperText) => {
  let inputHelperText = errorText;
  if(context !== undefined) {
    inputHelperText = context.getFieldError(id);
  }

  const isError = inputHelperText.length > 0;

  if(!isError && helperText.length > 0) {
    inputHelperText = helperText;
  }

  return {
    inputHelperText,
    isError
  }
}
