import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

function AppBarBackButton(props) {
  return (
    <IconButton
        component={Link}
        style={{color: '#FFF'}}
        to={props.url}
    >
        <Icon>arrow_back</Icon>
    </IconButton>
  );
}

AppBarBackButton.propTypes = {
  url: PropTypes.string.isRequired
};

AppBarBackButton.defaultProps = {

}

export default AppBarBackButton;
