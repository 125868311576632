import React from 'react'
import clsx from 'classnames'
import get from 'lodash/get'
import {
  FormControl,
  FormHelperText,
  FilledInput,
  InputLabel,
  Input,
  OutlinedInput
} from '@material-ui/core'
import PropTypes from 'prop-types'

import FormContext from './FormContext'
import { getInputHelperText, renderHelperText } from './helpers'

const GuiInput = (props) => {
  const context = React.useContext(FormContext)

  function onChange(e) {
    if (!!props.onChange) {
      props.onChange({ id: props.id, value: e.target.value }, context)
    } else {
      context.onChange({ id: props.id, value: e.target.value })
    }
  }

  const {
    className,
    errorText,
    formControllProps,
    fullWidth,
    helperText,
    label,
    margin,
    value,
    variant,
    ...restProps
  } = props

  let inputValue = value
  if (inputValue === undefined) {
    inputValue = get(context.data, props.id)
  }

  const inputClassName = clsx('gui-form-input', className)

  let Component = null

  if (variant === 'outlined') {
    Component = OutlinedInput
  } else if (variant === 'filled') {
    Component = FilledInput
  } else if (variant === 'standard') {
    Component = Input
  }

  const { inputHelperText, isError } = getInputHelperText(
    props.id,
    context,
    errorText,
    helperText
  )

  return (
    <FormControl
      {...formControllProps}
      error={isError}
      className={inputClassName}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
    >
      {!!label && <InputLabel id={`${props.id}-label`}>{label}</InputLabel>}
      <Component
        {...restProps}
        fullWidth={fullWidth}
        error={isError}
        value={inputValue}
        onChange={onChange}
        label={label}
      />
      {renderHelperText(inputHelperText, errorText)}
    </FormControl>
  )
}

GuiInput.propTypes = {
  id: PropTypes.string.isRequired,
  margin: PropTypes.string
}

GuiInput.defaultProps = {
  className: [],
  errorText: '',
  helperText: '',
  margin: 'normal',
  variant: 'outlined'
}

export default GuiInput
