import React from 'react';
import Chip from '@material-ui/core/Chip';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types'
import { equals, mergeAll } from "ramda";

import createColorStyles, { name } from "../createColorStyles";

const variants = ["default", "outlined"];
const defaultColors = ["default", "primary", "secondary"];
const colors = ["blue", "green", "red", "yellow"];

const isDefault = equals(variants[0]);
const isOutlined = equals(variants[1]);

function template(variant, color, theme) {
    return mergeAll([
        isDefault(variant) && {
            color: theme.palette[color].contrastText,
            backgroundColor: theme.palette[color].main
        },
        isOutlined(variant) && {
            color: theme.palette[color].main,
            borderColor: alpha(theme.palette[color].main, 0.5)
        }
    ]);
}

const useStyles = makeStyles(theme => ({
  ...createColorStyles(colors, variants, template, theme)
}));

function GuiChip(props) {
    const classes = useStyles();

    const { color, className, ...restProps } = props;

    let extraClass = [];
    let extraProps = {};

    if(defaultColors.indexOf(color) === -1) {
        extraClass.push(classes[name(props.variant, color)]);
    } else {
        extraProps.color = color;
    }

    extraProps.className = classnames(
        className,
        extraClass.join(' ')
    );

    return (
        <Chip
            {...restProps}
            {...extraProps}
        />
    );
}

GuiChip.propTypes = {
    color: PropTypes.string,
};

GuiChip.defaultProps = {
    className: "",
    color: "default",
    variant: "default"
};

export default GuiChip;
