import React, { useEffect, useState } from 'react';
import clsx from 'classnames';
import get from 'lodash/get';

import {
    FormControl,
    FilledInput,
    Grid,
    Icon,
    InputAdornment,
    InputLabel,
    Input,
    List,
    ListItem,
    ListItemText,
    OutlinedInput
} from '@material-ui/core';
import PropTypes from 'prop-types'

import FormContext from "./FormContext";

import Button from '../Button';
import Modal from '../Modal';
import usePrevious from '../usePrevious';
import { renderHelperText } from './helpers';

const GuiInputTimePicker = (props) => {
    const context = React.useContext(FormContext);
    const [openModal, setOpenModal] = useState(false);

    const {
      className,
      errorText,
      formControlProps,
      fullWidth,
      helperText,
      interval,
      label,
      margin,
      max,
      min,
      output,
      value,
      variant,
      ...restProps
    } = props;

    let initTime = {
      h: null,
      m: null
    };

    let inputValue = value;
    if(inputValue === undefined) {
      inputValue = get(context.data, props.id);
    }

    if(typeof inputValue === 'string') {
      const splited = inputValue.split(':');

      if(splited.length > 1) {
        initTime.h = parseInt(splited[0], 10);
        initTime.m = parseInt(splited[1], 10);
      }
    } else if(typeof inputValue === 'number') {
      initTime.h = Math.floor(inputValue / 60);
      initTime.m = inputValue % 60;
      inputValue = initTime.h.toString().padStart(2, 0)+":"+initTime.m.toString().padStart(2, 0);
    } else if(inputValue === null) {
      inputValue = '';
    }

    const prevValue = usePrevious(inputValue);

    const [time, setTime] = useState(initTime);
    useEffect(() => {
      if(inputValue !== prevValue) {
        setTime(initTime);
      }
    }, [inputValue])

    let hours = [];
    for(let h = min.h; h <= max.h; ++h) {
      let minutes = [];
      let startOn = 0;
      let endOn = 60;

      if(h === min.h) {
        if(min.m % interval !== 0) {
          startOn = min.m + interval - (min.m % interval);
        } else {
          startOn = min.m;
        }

        if(startOn >= 60) {
          continue;
        }
      } else if(h === max.h) {
        if(max.m % interval !== 0) {
          endOn = max.m - (max.m % interval);
        } else {
          endOn = max.m;
        }
      }

      for(let m = startOn; m < endOn; m += interval) {
        minutes.push(m);
      }

      hours.push({h: h, m: minutes});
    }

    const onChange = () => {
      let value = null;
      if(time.h !== null && time.m !== null) {
        if(output === 'time') {
          value = time.h.toString().padStart(2, 0)+":"+time.m.toString().padStart(2, 0);
        } else if(output === 'int') {
          value = time.h * 60 + time.m;
        }
      }

      if(!!props.onChange) {
        props.onChange({id: props.id, value}, context);
      } else {
        context.onChange({id: props.id, value});
      }

      setOpenModal(false);
    }

    const onChangeInput = (e) => {
      e.preventDefault();
    }

    const onClick = (event) => {
      if(!props.disabled) {
        setOpenModal(true);
      }
    }
    const onClickHour = (event, h) => {
      const hour = hours.find(item => item.h === h);

      let m = time.m;
      if(hour.m.indexOf(time.m) === -1) {
        m = hour.m[0];
      }

      setTime({h,m});
    }
    const onClickMinute = (event, minute) => {
      setTime({
        h: time.h,
        m: minute
      });
    }
    const onCloseModal = (event) => {
      setOpenModal(false);
    }

    const renderContent = () => {
      if(!openModal) {
        return null;
      }

      return (
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6} style={{maxHeight: window.innerHeight - 190, overflowY: 'auto'}}>
            <List style={{paddingTop: 0}}>
              { hours.map(item => renderHour(item)) }
            </List>
          </Grid>
          <Grid item xs={6} style={{maxHeight: window.innerHeight - 190, overflowY: 'auto'}}>
            <List style={{paddingTop: 0}}>
              { renderMinutes() }
            </List>
          </Grid>
        </Grid>
      )
    }

    const renderHour = (item) => {
      const selected = item.h === time.h;

      return (
        <ListItem
          autoFocus={selected}
          button
          key={`hour-${item.h}`}
          onClick={event => onClickHour(event, item.h)}
          selected={selected}
        >
          <ListItemText
            primary={item.h.toString().padStart(2, 0)}
          />
        </ListItem>
      )
    }

    const renderMinutes = () => {
      if(time.h === null) {
        return null;
      }

      const hour = hours.find(item => item.h === time.h);

      return hour.m.map(item => renderMinute(item));
    }

    const renderMinute = (item) => {
      const selected = item === time.m;

      return (
        <ListItem
          autoFocus={selected}
          button
          key={`minute-${item}`}
          onClick={event => onClickMinute(event, item)}
          selected={selected}
        >
          <ListItemText
            primary={item.toString().padStart(2, 0)}
          />
        </ListItem>
      )
    }

    const inputClassName = clsx(
        'gui-form-input',
        className
    );

    let inputHelperText = errorText;
    if(context !== undefined) {
      inputHelperText = context.getFieldError(props.id);
    }
    const isError = inputHelperText.length > 0;

    if(!isError && helperText.length > 0) {
        inputHelperText = helperText;
    }

    let Component = null;

    if(variant === 'outlined') {
        Component = OutlinedInput;
    } else if(variant === 'filled') {
        Component = FilledInput;
    } else if(variant === 'standard') {
        Component = Input;
    }

    return (
      <React.Fragment>
        <FormControl
            {...formControlProps}
            error={isError}
            className={inputClassName}
            fullWidth={fullWidth}
            margin={margin}
            variant={variant}
        >
            { !!label && <InputLabel id={`${props.id}-label`}>{label}</InputLabel> }
            <Component
                {...restProps}
                fullWidth={fullWidth}
                error={isError}
                value={inputValue}
                onChange={onChangeInput}
                onClick={onClick}
                label={label}
                startAdornment={(
                  <InputAdornment position="start">
                    <Icon>schedule</Icon>
                  </InputAdornment>
                )}
            />
            { renderHelperText(inputHelperText, isError) }
        </FormControl>
        <Modal
            onClose={onCloseModal}
            open={openModal}
            maxWidth="xs"
        >
            <Modal.Title
                onClose={onCloseModal}
            >
              Choose time
            </Modal.Title>
            <Modal.Content>
              {renderContent()}
              <Button
                color="blue"
                disabled={time.h === null || time.m === null}
                fullWidth
                onClick={onChange}
              >
                Use
              </Button>
            </Modal.Content>
        </Modal>
      </React.Fragment>
    )
}

GuiInputTimePicker.propTypes = {
    id: PropTypes.string.isRequired,
    margin: PropTypes.string,
};

GuiInputTimePicker.defaultProps = {
    className: [],
    errorText: '',
    formControlProps: {},
    helperText: '',
    interval: 1,
    margin: "normal",
    max: {h: 23, m: 60},
    min: {h: 0, m: 0},
    output: "time",
    variant: "outlined"
};

export default GuiInputTimePicker;
